import React, { Fragment } from 'react';
import ReactModal from 'react-modal';
import styled, { css, createGlobalStyle } from 'styled-components';
import { rgba } from 'polished';

import { colors, fonts } from '../../theme';
import { Icon } from '../Icon';
import { buttonResetStyles } from '../Button';

interface StyledModalProps extends ReactModal.Props {
  className?: string;
  customClassName?: string;
  isSmall?: boolean;
}

export interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onCloseClick: () => void;
  small?: boolean;
  title?: React.ReactNode;
  style?: ReactModal.Styles;
}

type SizeShape = { w: number; h: number };

const SIZE = {
  w: 800,
  h: 600
};

const SIZE_SMALL = {
  w: 600,
  h: 240
};

const getSize = (props: StyledModalProps): SizeShape => {
  if (!props.isSmall) {
    return SIZE;
  }

  return SIZE_SMALL;
};

const StyledModalWrapper: React.FC<StyledModalProps> = ({
  className,
  customClassName,
  ...props
}) => {
  const classNameBase =
    customClassName && className
      ? className.replace(customClassName, '').trim()
      : className;

  const classNames = [
    classNameBase,
    ...(customClassName ? [customClassName] : [])
  ];

  const contentClassNames = classNames.map((cn) => `${cn}__content`).join(' ');
  const overlayClassNames = classNames.map((cn) => `${cn}__overlay`).join(' ');

  return (
    <ReactModal
      {...props}
      portalClassName={className}
      className={contentClassNames}
      overlayClassName={overlayClassNames}
    />
  );
};

const StyledModal = styled(StyledModalWrapper).attrs(({ className }) => ({
  customClassName: className
}))((props) => {
  const { w, h } = getSize(props);

  return css`
    &__content {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: 0 2px 5px 0 rgba(197, 197, 197, 0.5);
      background: ${colors.white};
      width: ${w}px;
      height: ${h}px;
      max-width: calc(100% - 28px);
      max-height: calc(100% - 28px);
      outline: none;
    }

    &__overlay {
      background: ${rgba(colors.greyDark, 0.25)};
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 20;
    }
  `;
});

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.offWhite};
  padding: 10px 10px 6px;
  margin: 0 10px;
  box-shadow: 0 2px 5px 0px ${rgba(colors.white, 0.65)};
  z-index: 1;
`;

const StyledTitle = styled.h2`
  color: ${colors.greyDark};
  font-family: ${fonts.default};
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  margin: 0;
  padding-right: 16px;
`;

const CloseButton = styled.button`
  ${buttonResetStyles};
  background: transparent;
  line-height: 0;
  opacity: 0.7;
  transition: opacity 0.2s ease;
  will-change: opacity;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  overflow: auto;
`;

const BodyStyles = createGlobalStyle`
  body {
    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }
`;

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  onCloseClick,
  small,
  title,
  ...props
}) => (
  <Fragment>
    <BodyStyles />

    <StyledModal
      {...props}
      isOpen={isOpen}
      onRequestClose={onCloseClick}
      isSmall={small}
    >
      <StyledHeader>
        {title && <StyledTitle>{title}</StyledTitle>}

        <CloseButton onClick={onCloseClick} title="Close">
          <Icon name="close" color="greyDark" />
        </CloseButton>
      </StyledHeader>

      <StyledContent>{children}</StyledContent>
    </StyledModal>
  </Fragment>
);

export const modalSetAppElement = ReactModal.setAppElement;
