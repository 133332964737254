import { colors, fonts } from '@rollioforce/rollio-ui';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { CheckboxGroupField, SliderField, TextareaField } from '../../index';

type FieldType =
  | 'button'
  | 'input'
  | 'select'
  | 'table'
  | 'textarea'
  | 'checkbox'
  | 'checkboxGroup'
  | 'condition'
  | 'custom'
  | 'row'
  | 'slider';

export interface FormReadOnlyProps {
  type: FieldType;
  fieldLabel?: string;
  fieldValue?: any;
  field?: any;
}

const ReadOnlyField = styled.div`
  font-family: ${fonts.default};
  width: 280px;
  max-width: 100%;
`;

const FieldLabel = styled.label`
  color: ${colors.greyLight};
  font-size: 12px;
  user-select: none;
`;

const FieldValue = styled.span`
  display: block;
  box-sizing: border-box;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
  box-shadow: none;
  color: ${colors.greyDark};
`;

export const FormReadOnly: React.FC<FormReadOnlyProps> = ({
  type,
  fieldLabel,
  fieldValue,
  field
}) => {
  const value: any = useMemo(() => {
    if (type === 'input') {
      return fieldValue;
    }
    if (type === 'checkbox') {
      return fieldValue ? 'Yes' : 'No';
    }
    if (type === 'checkboxGroup') {
      const checkboxGroupField = field as CheckboxGroupField;
      return (checkboxGroupField.checkboxes || [])
        .filter((checkbox) => (fieldValue || '').includes(checkbox.value))
        .map((checkbox) => checkbox.label)
        .join(', ');
    }
    if (type === 'select') {
      return Array.isArray(fieldValue)
        ? fieldValue.map((v: any) => v.label).join(', ')
        : fieldValue?.label;
    }
    if (type === 'slider') {
      const sliderField = field as SliderField;
      return `${fieldValue} / ${sliderField.fieldProps?.max}`;
    }
    if (type === 'table') {
      return fieldValue?.join(', ');
    }
    if (type === 'textarea') {
      const textareaField = field as TextareaField;
      return textareaField.fieldProps?.mentions
        ? fieldValue.replace(/@\[(.*?)\]\((.*?)\)/g, '@$1')
        : fieldValue;
    }
    return '';
  }, [type, fieldValue, field]);

  return (
    <ReadOnlyField>
      <FieldLabel>{fieldLabel}</FieldLabel>
      <FieldValue>{value || '-'}</FieldValue>
    </ReadOnlyField>
  );
};
