import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  forwardRef
} from 'react';
import * as yup from 'yup';
import {
  Button,
  ButtonProps,
  CheckboxProps,
  LabelBoxProps,
  InputProps,
  SliderProps,
  SelectProps,
  TextareaProps
} from '@rollioforce/rollio-ui';

import { FormWrap, FormRow, FormColumn, FormError, SubmitRow } from './parts';
import { FormFactory, OnBlurParams, OnChangeParams } from './FormFactory';
import { FormConditionProps } from './FormFactory/FormCondition';
import { FormTableProps } from './FormFactory/FormTable';
import { equals, lensPath, omit, set, delay, isEmpty } from '../../utils';

export type FormData = Record<string, any>;
export type FormFieldErrors = Record<string, string>;

export type KeyPath = string[];
export type Validate = 'onChange' | 'onBlur';

interface FieldBase extends React.HTMLAttributes<HTMLDivElement> {
  isVisible?: boolean;
  key: string;
  keyPath?: KeyPath;
  label?: string;
  validate?: Validate;
  onUpdateFormat?: (value: any) => any;
  valueFormat?: (value: any) => any;
}

export interface ButtonField extends FieldBase {
  fieldProps?: Partial<ButtonProps>;
  type: 'button';
}

export interface CheckboxField extends FieldBase {
  fieldProps?: Partial<CheckboxProps>;
  type: 'checkbox';
}

export interface CheckboxGroupField extends FieldBase {
  checkboxes?: Array<{ label: string; value: string | number }>;
  fieldProps?: LabelBoxProps;
  type: 'checkboxGroup';
}

export interface ConditionField extends FieldBase {
  fieldProps?: Partial<FormConditionProps>;
  type: 'condition';
}

export interface CustomField extends FieldBase {
  CustomComponent: React.FC<any>;
  fieldProps?: Record<string, any>;
  type: 'custom';
}

export interface InputField extends FieldBase {
  fieldProps?: Partial<InputProps>;
  type: 'input';
}

export interface RowField extends FieldBase {
  fields: FormFields;
  type: 'row';
}

export interface SelectField extends FieldBase {
  fieldProps?: Partial<SelectProps>;
  type: 'select';
}

export interface SliderField extends FieldBase {
  fieldProps?: Partial<SliderProps>;
  type: 'slider';
}

export interface TableField extends FieldBase {
  fieldProps?: Partial<FormTableProps>;
  type: 'table';
}

export interface TextareaField extends FieldBase {
  fieldProps?: Partial<TextareaProps>;
  type: 'textarea';
}

export type FormField =
  | ButtonField
  | CheckboxField
  | CheckboxGroupField
  | ConditionField
  | CustomField
  | InputField
  | RowField
  | SelectField
  | SliderField
  | TableField
  | TextareaField;

export type FormFields = FormField[];

export interface FormProps extends React.HTMLAttributes<HTMLDivElement> {
  enableReinitialize?: boolean;
  error?: string;
  errors?: FormFieldErrors;
  fields: FormFields;
  gutterSize?: 'normal' | 'small';
  initialData?: FormData;
  isReadOnly?: boolean;
  isSubmitting?: boolean;
  onChange?: (formData: FormData) => void;
  onSubmit?: (formData: FormData) => void;
  onValidate?: (isValid: boolean, formData: FormData) => void;
  saveButtonLabel?: string;
  showSave?: boolean;
  schema?: yup.ObjectSchema<any>;
}

export type FormRef = {
  submit: () => void;
  validate: (fieldKeys: string[]) => Promise<boolean>;
};

export type FormComponentType = React.ForwardRefExoticComponent<
  FormProps & React.RefAttributes<FormRef>
>;

export const Form = forwardRef(
  (
    {
      enableReinitialize,
      error,
      errors,
      fields,
      gutterSize = 'normal',
      initialData,
      isReadOnly,
      isSubmitting,
      onChange,
      onSubmit,
      onValidate,
      saveButtonLabel = 'Save',
      showSave = true,
      schema,
      ...props
    }: FormProps,
    ref: React.Ref<FormRef>
  ) => {
    const [formData, setFormData] = useState(initialData || {});
    const [formError, setFormError] = useState<FormFieldErrors>({});
    const [isPristine, setIsPristine] = useState(true);

    const disableSubmitButton = useMemo(
      () => isReadOnly || (schema && (isPristine || !isEmpty(formError))),
      [isReadOnly, isPristine, formError, schema]
    );

    const validateField = useCallback(
      async (fieldKey: string, value: string) => {
        if (schema) {
          try {
            await yup.reach(schema, fieldKey).validate(value);
            // if fixed, remove the error for that specific field key
            if (formError && formError[fieldKey]) {
              setFormError((d) => omit([fieldKey], d));
            }
            return true;
          } catch (e) {
            setFormError((d) => set(lensPath([fieldKey]), e.message, d));
            return false;
          }
        }
        return true;
      },
      [schema, formError]
    );

    const updateFormData = useCallback(
      ({ fieldKey, valuePath, validate, value }: OnChangeParams) => {
        if (!isReadOnly) {
          setIsPristine(false);

          setFormData((d) => set(lensPath(valuePath), value, d));
          // if schema provided, then validate
          if (schema && validate === 'onChange') {
            delay(validateField, fieldKey, value);
          }
        }
      },
      [isReadOnly, schema, validateField]
    );

    const onBlurField = useCallback(
      ({ fieldKey, validate, value }: OnBlurParams) => {
        // if schema provided, then validate
        if (!isReadOnly && schema && validate === 'onBlur') {
          validateField(fieldKey, value);
        }
      },
      [isReadOnly, schema, validateField]
    );

    const validateAll = useCallback(() => {
      if (schema) {
        try {
          schema.validateSync(formData, { abortEarly: false });

          if (onValidate) {
            onValidate(true, formData);
          }

          if (onSubmit) {
            onSubmit(formData);
          }
        } catch (e) {
          const { inner } = e;
          // validate every field
          inner.forEach((i: yup.ValidationError) => {
            const { path, value } = i;

            validateField(path, value);
          });

          if (onValidate) {
            onValidate(false, formData);
          }
        }
      } else if (onSubmit) {
        onSubmit(formData);
      }
    }, [schema, formData, validateField, onSubmit, onValidate]);

    const validateFields = useCallback(
      async (fieldKeys: string[]) => {
        const results = await Promise.all(
          fieldKeys.map((fieldKey) =>
            validateField(fieldKey, formData[fieldKey])
          )
        );

        const isValid = results.every((result) => result);

        if (onValidate) {
          onValidate(isValid, formData);
        }

        return isValid;
      },
      [formData, validateField, onValidate]
    );

    useEffect(() => {
      if (onChange && !equals(formData, initialData)) {
        onChange(formData);
      }
    }, [formData, initialData, onChange]);

    useEffect(() => {
      if (enableReinitialize && initialData) {
        setFormData(initialData);
      }
    }, [enableReinitialize, initialData]);

    useEffect(() => {
      if (errors) {
        setFormError(errors);
      }
    }, [errors]);

    useEffect(() => {
      if (ref) {
        if (typeof ref === 'object') {
          // eslint-disable-next-line no-param-reassign
          (ref as React.MutableRefObject<FormRef>).current = {
            submit: validateAll,
            validate: validateFields
          };
        }
      }
    }, [ref, validateAll, validateFields]);

    return (
      <FormWrap {...props} gutterSize={gutterSize}>
        <FormFactory
          formFields={fields}
          formData={formData}
          formError={formError}
          FormComponent={Form}
          isReadOnly={isReadOnly}
          onUpdate={updateFormData}
          onBlur={onBlurField}
        />

        {error && (
          <FormRow>
            <FormColumn>
              <FormError>{error}</FormError>
            </FormColumn>
          </FormRow>
        )}

        {showSave && (
          <SubmitRow>
            <FormColumn>
              <Button
                disabled={disableSubmitButton}
                loading={isSubmitting}
                onClick={validateAll}
              >
                {saveButtonLabel}
              </Button>
            </FormColumn>
          </SubmitRow>
        )}
      </FormWrap>
    );
  }
);
