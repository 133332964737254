import React from 'react';
import styled, { css } from 'styled-components';
import { LabelBox, colors, fonts } from '@rollioforce/rollio-ui';

interface FormWrapProps {
  gutterSize: GutterSize;
}

type GutterSize = 'normal' | 'small';

const GUTTER_SIZES: { [key in GutterSize]: number } = {
  normal: 20,
  small: 10
};

const getGutterSize = (size: GutterSize): number => GUTTER_SIZES[size] / 2;

export const FormColumn = styled.div``;

export const FormWrap = styled.div<FormWrapProps>(({ gutterSize }) => {
  const size = getGutterSize(gutterSize);

  return css`
    display: flex;
    flex-direction: column;
    margin: -${size}px;

    ${FormColumn} {
      padding: ${size}px;
    }
  `;
});

const FormFlexRow = styled.div`
  display: flex;
`;

export const SubmitRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FormRow: React.FC<{ label?: string }> = ({
  children,
  label,
  ...props
}) => {
  const flexRow = <FormFlexRow {...props}>{children}</FormFlexRow>;

  if (label) {
    return (
      <FormColumn {...props}>
        <LabelBox label={label} style={{ padding: '10px 5px 5px' }}>
          {flexRow}
        </LabelBox>
      </FormColumn>
    );
  }

  return flexRow;
};

export const FormError = styled.span`
  display: block;
  font-family: ${fonts.default};
  font-size: 13px;
  font-weight: bold;
  color: ${colors.red};
`;

export const FormFieldError = styled(FormError)`
  font-size: 11px;
  font-weight: normal;
  margin-top: 6px;
`;
