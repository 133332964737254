import React from 'react';
import styled from 'styled-components';

import { colors, fonts } from '../../theme';
import { Icon } from '../Icon';

type HTMLLabelProps = Omit<React.HTMLAttributes<HTMLLabelElement>, 'onChange'>;

export interface CheckboxProps extends HTMLLabelProps {
  checked: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledLabel = styled.label`
  color: ${colors.greyDark};
  font-family: ${fonts.default};
  font-size: 16px;
  line-height: 1;
  display: inline-block;
  cursor: pointer;
`;

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  appearance: none;
`;

const StyledIconWrap = styled.span`
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${colors.white};
  border-radius: 2px;
  border: solid 2px ${colors.greyLight};
  vertical-align: middle;

  ${StyledInput}:focus + & {
    background: ${colors.offWhite};
  }

  ${StyledInput}:checked + & {
    background: ${colors.primary};
    border-color: ${colors.primary};
  }

  ${StyledInput}:checked:disabled + & {
    background: ${colors.greyLight};
    border-color: ${colors.greyLight};
  }
`;

const StyledChildren = styled.span`
  vertical-align: middle;
  margin-left: 12px;

  ${StyledInput}:disabled ~ & {
    color: ${colors.greyLight};
  }
`;

const StyledIcon = styled(Icon)`
  position: relative;
  top: -1px;
  left: -1px;
`;

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  children,
  disabled,
  onChange,
  ...props
}) => (
  <StyledLabel {...props}>
    <StyledInput
      onChange={onChange}
      type="checkbox"
      checked={checked}
      disabled={disabled}
    />
    <StyledIconWrap>
      {checked && <StyledIcon name="check" color="white" size="small" />}
    </StyledIconWrap>
    {children && <StyledChildren>{children}</StyledChildren>}
  </StyledLabel>
);
