import React, { useCallback, useMemo } from 'react';
import { LabelBox, SelectValueType } from '@rollioforce/rollio-ui';

import { uniqueId } from '../../../../utils';
import { FormComponentType } from '../../index';
import { FormGroup } from './FormGroup';

export interface FormConditionProps {
  label?: string;
  facts?: SelectValueType[];
  isReadOnly?: boolean;
  data: any;
  FormComponent: FormComponentType;
  onChange: (data: any) => void;
}

export const FormCondition: React.FC<FormConditionProps> = ({
  label = '',
  data,
  FormComponent,
  facts,
  onChange
}) => {
  const mapInitialData = useCallback((block: any): any => {
    if (Array.isArray(block)) {
      return block.filter((b) => !b.deleted).map((b) => mapInitialData(b));
    }

    const group = block.all || block.any;
    if (group) {
      const key = block.all ? 'all' : 'any';
      return {
        id: block.id || uniqueId(),
        ...block,
        [key]: mapInitialData(group)
      };
    }

    return { id: block.id || uniqueId(), ...block };
  }, []);

  const mapData = useCallback((block: any, values: any): any => {
    if (Array.isArray(block)) {
      return block.map((b) => mapData(b, values));
    }

    const group = block.all || block.any;
    if (group) {
      const key = block.all ? 'all' : 'any';
      return block.id === values.id
        ? values
        : { ...block, [key]: mapData(group, values) };
    }

    return block.id === values.id ? values : block;
  }, []);

  const initialData = useMemo(() => data && mapInitialData(data), [
    data,
    mapInitialData
  ]);

  const onFormChange = (values: any): void => {
    const newData = mapData(initialData, values);
    onChange(newData);
  };

  return (
    <LabelBox label={label}>
      {initialData && (
        <FormGroup
          data={initialData}
          facts={facts}
          isMain
          FormComponent={FormComponent}
          onChange={onFormChange}
        />
      )}
    </LabelBox>
  );
};
