import * as yup from "yup";

export default yup.object().shape({
  first_name: yup.string().required().label("First Name"),
  last_name: yup.string().required().label("Last Name"),
  email: yup.string().email().required().label("Email"),
  profile_image_url: yup.string().url().label("Profile Image URL"),
  deployment_ids: yup
    .array()
    .of(yup.number())
    .nullable()
    .required()
    .label("Deployments"),
  is_active: yup.mixed().oneOf([0, 1]).label("Active"),
  is_admin: yup.mixed().oneOf([0, 1]).label("Admin"),
});
