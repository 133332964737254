import React, { useMemo } from 'react';
import {
  default as SelectComponent,
  Props as ReactSelectProps,
  InputProps,
  InputActionMeta,
  ValueType
} from 'react-select';
import styled, { css } from 'styled-components';

import {
  DropdownIndicator,
  EditableInput,
  FragmentPassThrough,
  GroupHeading,
  FixedInput,
  Menu,
  NullElement,
  Option
} from './parts';

export type SelectValueType = ValueType<any, any>;

export interface SelectProps extends ReactSelectProps {
  disabled?: boolean;
  fullWidth?: boolean;
  label: string;
  multiple?: boolean;
  onChange: (value: SelectValueType) => void;
  style?: React.CSSProperties;
  value: SelectValueType;
  isSearchable?: boolean;
}

type onChangeWhenSearchingFn = (option: any) => void;

type InputChangeFn = (newInput: string, action: InputActionMeta) => void;

type SearchProps = {
  onChange: (value: SelectValueType) => void;
  inputValue?: SelectValueType;
  onInputChange?: InputChangeFn;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
};

const StyledSelect = styled(SelectComponent)((props) => {
  const width = !props.fullWidth ? '280px' : '100%';

  return css`
    width: ${width};
  `;
});

export const Select: React.FC<SelectProps> = ({
  disabled,
  fullWidth,
  label,
  multiple,
  onChange,
  options,
  style,
  styles,
  value,
  isSearchable,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState(value ? value.label : '');

  const onInputChange: InputChangeFn = (newInput, { action }) => {
    if (action === 'input-blur') {
      setInputValue(value ? value.label : '');
    } else if (action === 'input-change') {
      setInputValue(newInput);
    }
  };

  const onChangeSelect: onChangeWhenSearchingFn = (option) => {
    onChange(option);
    setInputValue(option ? option.label : '');
  };

  const components = useMemo(
    () => ({
      Input({
        innerRef,
        menuIsOpen,
        onBlur,
        onFocus,
        selectProps,
        ...rest
      }: InputProps & ReactSelectProps): React.ReactNode {
        return isSearchable ? (
          <EditableInput
            {...rest}
            selectProps={selectProps}
            disabled={disabled}
            fullWidth={fullWidth}
            innerRef={innerRef}
            label={label}
            menuIsOpen={menuIsOpen}
            multiple={multiple}
            onBlur={onBlur}
            onFocus={(event) => {
              setInputValue('');
              if (onFocus) {
                onFocus(event);
              }
            }}
            value={value}
          />
        ) : (
          <FixedInput
            {...rest}
            disabled={disabled}
            fullWidth={fullWidth}
            innerRef={innerRef}
            label={label}
            menuIsOpen={menuIsOpen}
            multiple={multiple}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value}
          />
        );
      },
      Menu,
      DropdownIndicator,
      Option,
      GroupHeading,
      Control: FragmentPassThrough,
      ValueContainer: FragmentPassThrough,
      IndicatorsContainer: FragmentPassThrough,
      IndicatorSeparator: NullElement,
      Placeholder: NullElement,
      ClearIndicator: NullElement,
      MultiValueContainer: NullElement
    }),
    [disabled, fullWidth, label, multiple, value, isSearchable]
  );

  const selectStyles = useMemo(
    () => ({
      // @ts-ignore
      container: (p): React.CSSProperties => ({
        ...p,
        ...style
      }),
      ...styles
    }),
    [style, styles]
  );

  const searchableProps: SearchProps = {
    onChange
  };

  if (isSearchable) {
    searchableProps.inputValue = inputValue;
    searchableProps.onMenuOpen = () =>
      onInputChange('', { action: 'input-change' });
    searchableProps.onMenuClose = () => {
      if (value) onInputChange(value.label, { action: 'input-change' });
    };
    searchableProps.onInputChange = onInputChange;
    searchableProps.onChange = onChangeSelect;
  }

  return (
    <StyledSelect
      {...searchableProps}
      {...props}
      closeMenuOnScroll
      components={components}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      fullWidth={fullWidth}
      isDisabled={disabled}
      isMulti={multiple}
      isSearchable={isSearchable}
      openMenuOnFocus
      options={options}
      styles={selectStyles}
      value={value}
    />
  );
};
