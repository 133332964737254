import React from 'react';
import styled, { css } from 'styled-components';
import { darken, lighten, rgba } from 'polished';

import { buttonResetStyles } from '../Button';
import { Icon, IconName } from '../Icon';

import { colors, ColorName, fonts } from '../../theme';

interface BaseProps {
  background?: ColorName;
  disabled?: boolean;
  textColor?: ColorName;
}

type StyledBigButtonProps = BaseProps;

type HTMLButtonProps = React.HTMLAttributes<HTMLButtonElement>;

export interface BigButtonProps extends BaseProps, HTMLButtonProps {
  as?: any;
  icon?: IconName;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  description?: string;
  title?: string;
}

const ButtonInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ButtonInnerLabel = styled.div`
  display: flex;
  height: 115px;
  margin-right: 10px;
  margin-top: -10px;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
`;

const ButtonTitle = styled.h1`
  font-family: ${fonts.default};
  font-size: 18px;
  font-weight: 600;
  align-self: flex-start;
  margin-left: 0;
  margin-bottom: 10px;
`;

const IconWrapper = styled.div`
  height: 115px;
  display: inline-flex;
`;

const ButtonIcon = styled(Icon).attrs(() => ({ size: 'large' }))`
  display: flex;
  min-height: 45px;
  min-width: 45px;
  max-height: 45px;
  max-width: 45px;
  margin-right: 10px;
  align-self: center;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  will-change: opacity, visibility;
`;

const ButtonChevron = styled(Icon).attrs(() => ({
  size: 'medium'
}))`
  display: block;
  align-self: center;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  will-change: opacity, visibility;
  position: absolute;
  right: 5px;
  top: 45px;
`;

const ButtonDescription = styled.div`
  font-size: 12px;
  text-align: left;
  margin-left: 0;
  margin-right: 10px;
  padding-right: 10px;
  margin-top: 0;
`;

const StyledBigButton = styled.button<StyledBigButtonProps>((props) => {
  const { textColor, disabled, background } = props;
  const buttonBackground = background || colors.offWhite2;
  const backgroundHovered = darken(0.075, buttonBackground);
  const backgroundFocused = darken(0.115, buttonBackground);
  const backgroundDisabled = lighten(0.075, buttonBackground);
  const color = textColor ? colors[textColor] : colors.primary;
  const colorDisabled = rgba(color, 0.2);
  const bodyTextDisabled = rgba(colors.greyDark, 0.3);

  return css`
    ${buttonResetStyles};
    flex-direction: row;
    border-radius: 4px;
    padding: 0 0 16px 16px;
    background: ${buttonBackground};
    height: 115px;
    width: 300px;
    color: ${color};
    font-size: 12px;
    font-family: ${fonts.default};
    text-align: left;
    text-decoration: none;
    position: relative;
    transform: translate3d(0, 0, 0);
    transition: background 0.2s ease, box-shadow 0.25s ease-in-out;
    will-change: background, box-shadow;

    h1 {
      color: ${!disabled ? color : colorDisabled};
    }

    div {
      color: ${!disabled ? colors.greyDark : bodyTextDisabled};
    }

    svg {
      fill: ${!disabled ? color : colorDisabled};
    }

    ${!disabled &&
      css`
        &:hover {
          background: ${backgroundHovered};
        }

        &:focus:not(:hover) {
          background: ${backgroundFocused};
        }
      `}
    ${disabled &&
      css`
        cursor: not-allowed;
        background: ${backgroundDisabled};
        color: ${colorDisabled};
      `}
  `;
});

export const BigButton: React.FC<BigButtonProps> = ({
  icon,
  onClick,
  title,
  description,
  textColor,
  disabled,
  background,
  ...props
}) => (
  <StyledBigButton
    {...props}
    onClick={onClick}
    textColor={textColor}
    disabled={disabled}
    background={background}
  >
    <ButtonInnerWrapper>
      {icon && (
        <IconWrapper>
          <ButtonIcon name={icon} />
        </IconWrapper>
      )}
      <ButtonInnerLabel>
        <ButtonTitle>{title}</ButtonTitle>
        <ButtonDescription>{description}</ButtonDescription>
      </ButtonInnerLabel>
    </ButtonInnerWrapper>
    <ButtonChevron name="chevronRight" color="greyLight" />
  </StyledBigButton>
);
