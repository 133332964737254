import * as yup from "yup";

export default yup.object().shape({
  name: yup.string().required().label("Name"),
  deployment_id: yup.number().required().label("Deployment"),
  intent_id: yup.number().required().label("Action"),
  object_id: yup.number().required().label("Data"),
  template: yup.string().required().label("Conversation Template"),
  is_active: yup.mixed().oneOf([0, 1]).label("Active"),
});
