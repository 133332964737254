import * as yup from "yup";

export default yup.object().shape({
  config: yup
    .string()
    .required()
    .test("is-valid-json", "${path} is not a valid JSON", (value) => {
      try {
        if (value) JSON.parse(value);
        return true;
      } catch (e) {
        return false;
      }
    })
    .label("Config"),
  is_active: yup.mixed().oneOf([0, 1]).label("Active"),
});
