import React from 'react';
import styled, { css } from 'styled-components';
import { colors, fonts } from '../../theme';

export type TableLabelVariant = 'header' | 'default' | 'code';

export interface TableLabelProps extends React.HTMLAttributes<HTMLSpanElement> {
  as?: any;
  isLink?: boolean;
  variant?: TableLabelVariant;
}

const fontSize = {
  header: 16,
  default: 14,
  code: 12
};

const fontWeight = {
  header: 700,
  default: 600,
  code: 400
};

const textColor = {
  header: colors.greyDark,
  default: colors.greyLight,
  code: colors.primary
};

const hoverStyles = css`
  cursor: pointer;
  transition: color 0.2s ease;
  will-change: color;

  &:hover {
    color: ${colors.primary};
  }
`;

const StyledLabel = styled.span<TableLabelProps>(
  ({ isLink = false, variant = 'default' }) => css`
    display: ${variant !== 'code' ? 'block' : 'inline-block'};
    color: ${textColor[variant]};
    font-size: ${fontSize[variant]}px;
    font-weight: ${fontWeight[variant]};
    text-decoration: none;
    white-space: initial;

    ${variant === 'code' &&
      css`
        font-family: ${fonts.mono};
        line-height: 1;
        background: ${colors.offWhite2};
        padding: 3px;
        border-radius: 3px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
      `}

    ${isLink && hoverStyles}
  `
);

export const TableLabel: React.FC<TableLabelProps> = (props) => (
  <StyledLabel {...props} />
);
