import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, colors, fonts } from '@rollioforce/rollio-ui';

import { SIDEBAR_WIDTH } from '../Sidebar';

export interface HeaderNavLink {
  active?: boolean;
  id: string;
  label: React.ReactNode;
}

export type HeaderNavLinks = HeaderNavLink[];

export interface HeaderNavProps extends React.HTMLAttributes<HTMLDivElement> {
  linkItems: HeaderNavLinks;
  onMenuButtonClick?: () => void;
  padLeft?: boolean;
  renderLink?: (id: string, linkInner: React.ReactNode) => React.ReactNode;
  statusBar?: React.ReactNode;
}

export const HEADER_NAV_HEIGHT = 74;

const NavWrap = styled.nav<{ padLeft: boolean }>(
  ({ padLeft = false }) => css`
    background: ${colors.white};
    box-shadow: 0 2px 3px -1px rgba(197, 197, 197, 0.4);
    padding: 0 20px;
    height: ${HEADER_NAV_HEIGHT}px;
    display: flex;
    align-items: center;
    position: fixed;
    left: ${padLeft ? `${SIDEBAR_WIDTH}px` : 0};
    top: 0;
    right: 0;
    backface-visibility: hidden;
    z-index: 10;
    transition: left 0.2s ease;
  `
);

const MenuButton = styled.button`
  background: transparent;
  margin-right: 20px;
  appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
`;

const textStyle = css`
  font-size: 22px;
  color: ${colors.greyLight};
  font-family: ${fonts.default};
  font-weight: 600;
`;

const NavItem = styled.div`
  &:not(:last-of-type)::after {
    content: '/';
    margin: 0 7px;
    ${textStyle}
  }

  &:last-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const LinkInner = styled.span<{ isActive?: boolean }>(
  ({ isActive }) => css`
    ${textStyle}

    ${isActive &&
    css`
      color: ${colors.primary};
      font-weight: 700;
    `}
  `
);

const LinkItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const StatusBar = styled.span`
  font-family: ${fonts.default};
  color: ${colors.greyLight};
  font-size: 14px;
  line-height: 22px;
`;

export const HeaderNav: React.FC<HeaderNavProps> = ({
  linkItems,
  onMenuButtonClick,
  padLeft = false,
  renderLink = (_, l): React.ReactNode => l,
  statusBar,
  ...props
}) => (
  <NavWrap {...props} padLeft={padLeft}>
    <MenuButton onClick={onMenuButtonClick}>
      <Icon name="menu" color="greyDark" size="large" />
    </MenuButton>
    <LinkItemsContainer>
      {linkItems.map(({ active, label, id }) => {
        const linkInner = <LinkInner isActive={active}>{label}</LinkInner>;

        return <NavItem key={id}>{renderLink(id, linkInner)}</NavItem>;
      })}
    </LinkItemsContainer>
    {statusBar && <StatusBar>{statusBar}</StatusBar>}
  </NavWrap>
);
