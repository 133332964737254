import React, { Fragment } from 'react';
import {
  Button,
  Checkbox,
  Input,
  LabelBox,
  Select,
  Slider,
  Textarea
} from '@rollioforce/rollio-ui';

import { FormCondition } from './FormCondition';
import { FormTable } from './FormTable';
import { FormReadOnly } from './FormReadOnly';
import { FormColumn, FormRow, FormFieldError } from '../parts';
// types
import {
  FormFields,
  FormComponentType,
  FormData,
  FormFieldErrors,
  CheckboxField,
  CheckboxGroupField,
  ConditionField,
  CustomField,
  ButtonField,
  InputField,
  RowField,
  SelectField,
  SliderField,
  TableField,
  Validate,
  KeyPath,
  TextareaField
} from '../index';
import { includes, pathOr, toggleListItem } from '../../../utils';

export type OnBlurParams = {
  fieldKey: string;
  validate?: Validate;
  value: any;
};

export type OnChangeParams = {
  fieldKey: string;
  valuePath: KeyPath;
  validate?: Validate;
  value: any;
};

interface Props {
  formFields: FormFields;
  formData: FormData;
  formError: FormFieldErrors;
  FormComponent: FormComponentType;
  isReadOnly?: boolean;
  onUpdate: (params: OnChangeParams) => void;
  onBlur: (params: OnBlurParams) => void;
}

const DEFAULT_VALUE = {
  button: null,
  checkbox: false,
  checkboxGroup: [],
  condition: { all: [] },
  custom: null,
  input: '',
  row: null,
  select: null,
  slider: 0,
  table: [],
  textarea: ''
};

export const FormFactory: React.FC<Props> = (props) => {
  const {
    formData,
    formError,
    formFields,
    FormComponent,
    isReadOnly,
    onUpdate,
    onBlur
  } = props;

  const hasFormFields = formFields && !!formFields.length;

  return (
    <Fragment>
      {hasFormFields &&
        formFields.map(
          ({
            isVisible,
            key,
            keyPath,
            label,
            onUpdateFormat = (v): any => v,
            type,
            validate,
            valueFormat = (v): any => v,
            ...field
          }) => {
            const valuePath = keyPath || [key];
            const value = pathOr(DEFAULT_VALUE[type], valuePath, formData);
            const formattedValue = valueFormat(value);

            const fieldLabel = label || '';
            const fieldValue =
              typeof formattedValue !== 'undefined' ? formattedValue : value;

            // validating with yup
            const fieldError = formError[key];

            const fieldVisible = isVisible ?? true;

            if (!fieldVisible) {
              return null;
            }

            if (isReadOnly && type !== 'row' && type !== 'custom') {
              return (
                <FormColumn key={key}>
                  <FormReadOnly
                    type={type}
                    fieldLabel={fieldLabel}
                    fieldValue={fieldValue}
                    field={field}
                  />
                </FormColumn>
              );
            }

            if (type === 'button') {
              const { fieldProps, ...colProps } = field as ButtonField;

              return (
                <FormColumn {...colProps} key={key}>
                  <Button {...fieldProps}>{fieldLabel}</Button>
                </FormColumn>
              );
            }

            if (type === 'checkbox') {
              const { fieldProps, ...colProps } = field as CheckboxField;

              return (
                <FormColumn {...colProps} key={key}>
                  <Checkbox
                    {...fieldProps}
                    checked={fieldValue}
                    onChange={(e): void =>
                      onUpdate({
                        fieldKey: key,
                        validate,
                        valuePath,
                        value: onUpdateFormat(e.target.checked)
                      })
                    }
                  >
                    {fieldLabel}
                  </Checkbox>

                  {fieldError && <FormFieldError>{fieldError}</FormFieldError>}
                </FormColumn>
              );
            }

            if (type === 'checkboxGroup') {
              const {
                checkboxes,
                fieldProps,
                ...colProps
              } = field as CheckboxGroupField;

              return (
                <FormColumn {...colProps} key={key}>
                  <LabelBox {...fieldProps} label={fieldLabel}>
                    {checkboxes &&
                      checkboxes.map((checkbox) => {
                        const isChecked = includes(checkbox.value, fieldValue);
                        const onChange = (): void =>
                          onUpdate({
                            fieldKey: key,
                            validate,
                            valuePath,
                            value: onUpdateFormat(
                              toggleListItem(checkbox.value, fieldValue)
                            )
                          });

                        return (
                          <Checkbox
                            key={checkbox.label}
                            checked={isChecked}
                            onChange={onChange}
                            style={{ marginRight: 20 }}
                          >
                            {checkbox.label}
                          </Checkbox>
                        );
                      })}

                    {fieldError && (
                      <FormFieldError>{fieldError}</FormFieldError>
                    )}
                  </LabelBox>
                </FormColumn>
              );
            }

            if (type === 'condition') {
              const { fieldProps, ...colProps } = field as ConditionField;

              return (
                <FormColumn {...colProps} key={key}>
                  <FormCondition
                    {...fieldProps}
                    label={fieldLabel}
                    data={fieldValue}
                    FormComponent={FormComponent}
                    isReadOnly={isReadOnly}
                    onChange={(v): void =>
                      onUpdate({
                        fieldKey: key,
                        validate,
                        valuePath,
                        value: onUpdateFormat(v)
                      })
                    }
                  />
                </FormColumn>
              );
            }

            if (type === 'custom') {
              const {
                CustomComponent,
                fieldProps,
                ...colProps
              } = field as CustomField;

              return (
                <FormColumn {...colProps} key={key}>
                  <CustomComponent
                    {...fieldProps}
                    onChange={(v: any): void =>
                      onUpdate({
                        fieldKey: key,
                        validate,
                        valuePath,
                        value: onUpdateFormat(v)
                      })
                    }
                    value={fieldValue}
                    formData={formData}
                  />

                  {fieldError && <FormFieldError>{fieldError}</FormFieldError>}
                </FormColumn>
              );
            }

            if (type === 'input') {
              const { fieldProps, ...colProps } = field as InputField;

              return (
                <FormColumn {...colProps} key={key}>
                  <Input
                    {...fieldProps}
                    label={fieldLabel}
                    name={key}
                    onChange={(e): void =>
                      onUpdate({
                        fieldKey: key,
                        validate,
                        valuePath,
                        value: onUpdateFormat(e.target.value)
                      })
                    }
                    value={fieldValue}
                    disabled={fieldProps?.disabled ?? isReadOnly}
                    onBlur={(): void =>
                      onBlur({
                        fieldKey: key,
                        validate,
                        value: onUpdateFormat(fieldValue)
                      })
                    }
                  />

                  {fieldError && <FormFieldError>{fieldError}</FormFieldError>}
                </FormColumn>
              );
            }

            if (type === 'row') {
              const { fields, ...rowProps } = field as RowField;
              const hasFields = fields && !!fields.length;

              if (hasFields) {
                return (
                  <FormRow {...rowProps} key={key} label={fieldLabel}>
                    <FormFactory {...props} formFields={fields} />
                  </FormRow>
                );
              }
            }

            if (type === 'select') {
              const { fieldProps, ...colProps } = field as SelectField;
              const { options = [], ...fieldRest } = fieldProps || {};

              return (
                <FormColumn {...colProps} key={key}>
                  <Select
                    {...fieldRest}
                    label={fieldLabel}
                    name={key}
                    onChange={(v): void =>
                      onUpdate({
                        fieldKey: key,
                        validate,
                        valuePath,
                        value: onUpdateFormat(v)
                      })
                    }
                    options={options}
                    value={fieldValue}
                    disabled={fieldProps?.disabled ?? isReadOnly}
                  />

                  {fieldError && <FormFieldError>{fieldError}</FormFieldError>}
                </FormColumn>
              );
            }

            if (type === 'slider') {
              const { fieldProps, ...colProps } = field as SliderField;

              return (
                <FormColumn {...colProps} key={key}>
                  <Slider
                    {...fieldProps}
                    label={fieldLabel}
                    onChange={(v): void =>
                      onUpdate({
                        fieldKey: key,
                        validate,
                        valuePath,
                        value: onUpdateFormat(v)
                      })
                    }
                    value={fieldValue}
                    disabled={fieldProps?.disabled ?? isReadOnly}
                  />

                  {fieldError && <FormFieldError>{fieldError}</FormFieldError>}
                </FormColumn>
              );
            }

            if (type === 'table') {
              const { fieldProps, ...colProps } = field as TableField;

              return (
                <FormColumn {...colProps} key={key}>
                  <FormTable
                    {...fieldProps}
                    label={fieldLabel}
                    data={fieldValue}
                    FormComponent={FormComponent}
                    isReadOnly={isReadOnly}
                    onChange={(v): void =>
                      onUpdate({
                        fieldKey: key,
                        validate,
                        valuePath,
                        value: onUpdateFormat(v)
                      })
                    }
                  />
                </FormColumn>
              );
            }

            if (type === 'textarea') {
              const { fieldProps, ...colProps } = field as TextareaField;

              return (
                <FormColumn {...colProps} key={key}>
                  <Textarea
                    {...fieldProps}
                    label={fieldLabel}
                    name={key}
                    onChange={(e): void =>
                      onUpdate({
                        fieldKey: key,
                        validate,
                        valuePath,
                        value: onUpdateFormat(e.target.value)
                      })
                    }
                    value={fieldValue}
                    disabled={fieldProps?.disabled ?? isReadOnly}
                    onBlur={(): void =>
                      onBlur({
                        fieldKey: key,
                        validate,
                        value: onUpdateFormat(fieldValue)
                      })
                    }
                  />

                  {fieldError && <FormFieldError>{fieldError}</FormFieldError>}
                </FormColumn>
              );
            }

            return null;
          }
        )}
    </Fragment>
  );
};
