import React from 'react';
import styled, { css } from 'styled-components';

import { ColorName, colors, fonts } from '../../theme';

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  small?: boolean;
  background?: ColorName;
}

const StyledBadge = styled.div<BadgeProps>((props) => {
  const background = colors[props.background || 'greyLight'];

  return css`
    display: inline-flex;
    justify-content: center;
    font-family: ${fonts.default};
    font-size: 12px;
    font-weight: 600;
    background-color: ${background};
    color: ${colors.white};
    align-items: center;
    border-radius: 2px;
    height: 24px;
    min-width: 24px;

    ${props.small &&
      css`
        font-size: 10px;
        height: 18px;
        min-width: 18px;
      `}
  `;
});

const StyledBadgeContent = styled.span`
  padding-left: 6px;
  padding-right: 6px;
`;

export const Badge: React.FC<BadgeProps> = ({ children, ...props }) => (
  <StyledBadge {...props}>
    <StyledBadgeContent>{children}</StyledBadgeContent>
  </StyledBadge>
);
