import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { darken, lighten, rgba } from 'polished';

import { Icon, IconName } from '../Icon';
import { colors, ColorName, fonts } from '../../theme';
import { isString } from '../../utils';

import { LoadingIndicator } from './LoadingIndicator';

interface BaseProps {
  background?: ColorName;
  dark?: boolean;
  disabled?: boolean;
  small?: boolean;
  textColor?: ColorName;
}

interface StyledButtonProps extends BaseProps {
  isLoading?: boolean;
  onlyIcon: boolean;
  textColor: ColorName;
}

type HTMLButtonProps = React.HTMLAttributes<HTMLButtonElement>;

export interface ButtonProps extends BaseProps, HTMLButtonProps {
  as?: any;
  children?: React.ReactNode;
  icon?: IconName;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

type StyleGeneratorFn = (props: StyledButtonProps) => string;

type ShadowGenerator = (color: string, active?: boolean) => string;

const getBg: StyleGeneratorFn = (props) => {
  if (props.background) {
    return colors[props.background];
  }
  if (props.dark) {
    return colors.white;
  }
  return colors.primary;
};

export const buttonResetStyles = css`
  padding: 0;
  border: 0;
  margin: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
`;

const buttonBoxShadow: ShadowGenerator = (color, active = false) => {
  const size = !active ? '0 2px 12px -2px' : '0 2px 8px 0';

  return `${size} ${rgba(color, !active ? 0.4 : 0.5)}`;
};

const PADDING = { x: 15, y: 10 };
const PADDING_SMALL = { x: 14, y: 8 };

const getPadding: StyleGeneratorFn = (props) => {
  const { x, y } = !props.small ? PADDING : PADDING_SMALL;

  return props.onlyIcon ? `${y - 1}px` : `${y}px ${x}px`;
};

const ButtonIcon = styled(Icon).attrs(() => ({ size: 'small' }))`
  display: block;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  will-change: opacity, visibility;
`;

const StyledButton = styled.button<StyledButtonProps>((props) => {
  const background = getBg(props);
  const backgroundHovered = darken(0.075, background);
  const backgroundFocused = darken(0.115, background);
  const backgroundDisabled = lighten(0.075, background);

  const color = colors[props.textColor];
  const colorDisabled = rgba(color, 0.85);

  const padding = getPadding(props);
  const fontSize = props.small ? '13px' : '15px';

  return css`
    ${buttonResetStyles};
    border-radius: 4px;
    padding: ${padding};
    background: ${background};
    color: ${color};
    font-size: ${fontSize};
    font-family: ${fonts.default};
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    position: relative;
    transform: translate3d(0, 0, 0);
    transition: background 0.2s ease, box-shadow 0.25s ease-in-out;
    will-change: background, box-shadow;

    ${!props.disabled &&
      css`
        &:hover {
          background: ${backgroundHovered};
          box-shadow: ${buttonBoxShadow(backgroundHovered)};

          &:active {
            box-shadow: ${buttonBoxShadow(backgroundHovered, true)};
          }
        }

        &:focus:not(:hover) {
          background: ${backgroundFocused};
          box-shadow: ${buttonBoxShadow(backgroundFocused)};

          &:active {
            box-shadow: ${buttonBoxShadow(backgroundFocused, true)};
          }
        }
      `}

      ${props.disabled &&
        css`
          cursor: not-allowed;
          background: ${backgroundDisabled};
          color: ${colorDisabled};
        `}

      ${props.isLoading &&
        css`
          color: transparent;
          cursor: wait;

          ${ButtonIcon} {
            opacity: 0;
            visibility: hidden;
          }
        `}

      ${ButtonIcon} {
        padding: ${props.onlyIcon && !props.small ? '1px' : 0};
        margin-left: ${!props.small ? '12px' : '8px'};

        &:only-child {
          margin-left: 0;
        }
      }
    `;
});

const ButtonInner = styled.span`
  display: flex;
  align-items: center;
`;

const Children = styled.span``;

export const Button: React.FC<ButtonProps> = ({
  background,
  children,
  dark,
  disabled,
  icon,
  textColor,
  loading,
  onClick,
  small,
  ...props
}) => {
  const innerColor = dark ? 'greyLight' : textColor || 'white';
  const hasIcon = isString(icon);
  const InnerWrap = hasIcon && children ? ButtonInner : Fragment;
  const ChildrenWrap = hasIcon ? Children : Fragment;
  const onlyIcon = hasIcon && !children;

  return (
    <StyledButton
      {...props}
      background={background}
      dark={dark}
      disabled={disabled}
      isLoading={loading}
      onClick={onClick}
      onlyIcon={onlyIcon}
      small={small}
      textColor={innerColor}
    >
      <InnerWrap>
        {children && <ChildrenWrap>{children}</ChildrenWrap>}
        {icon && hasIcon && <ButtonIcon name={icon} color={innerColor} />}
      </InnerWrap>

      {loading && (
        <LoadingIndicator dark={dark} color={innerColor} small={small} />
      )}
    </StyledButton>
  );
};
