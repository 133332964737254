import React from 'react';
import styled, { css } from 'styled-components';
import { Button, colors, SelectValueType } from '@rollioforce/rollio-ui';

import { uniqueId } from '../../../../utils';
import { FormComponentType } from '../../index';
import { FormRule } from './FormRule';

export interface FormGroupProps {
  data: any;
  facts?: SelectValueType[];
  isMain?: boolean;
  FormComponent: FormComponentType;
  onChange: (values: any) => any;
}

const ALL_ANY_VALUES = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'any',
    label: 'Any'
  }
];

const FormContainer = styled.div<any>(
  ({ isMain }) => css`
    min-height: 80px;
    position: relative;
    padding-left: 15px;

    ${!isMain &&
    css`
      margin: 10px;
      border: 2px solid ${colors.offWhite2};
      border-radius: 6px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: -9px;
        width: 7px;
        height: calc(50% + 20px);
        border-style: solid;
        border-color: ${colors.offWhite2};
      }
      &:before {
        top: -17px;
        border-width: 0 0 2px 2px;
      }
      &:after {
        top: calc(50%);
        border-width: 0 0 0 2px;
      }
      &:first-child::before {
        top: -12px;
        height: calc(50% + 12px);
      }
      &:last-child {
        &::before {
          border-radius: 0 0 0 4px;
        }
        &::after {
          display: none;
        }
      }
    `};
  `
);

const GroupOptions = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 10px;
  margin-left: -10px;
`;

export const GroupContainer = styled.div``;

const ButtonContainer = styled.div`
  height: 56px;
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;
  }
`;

export const FormGroup: React.FC<FormGroupProps> = ({
  data,
  facts,
  isMain,
  FormComponent,
  onChange
}) => {
  const value = data.all ? 'all' : 'any';
  const fields: any[] = data[value];
  const initialData = { allAny: value };

  const onAddGroupClick = (): void => {
    onChange({
      id: data.id,
      [value]: [...fields, { id: uniqueId(), all: [] }]
    });
  };

  const onAddRuleClick = (): void => {
    onChange({
      id: data.id,
      [value]: [
        ...fields,
        { id: uniqueId(), fact: '', operator: '', value: '' }
      ]
    });
  };

  const onRemoveGroup = (): void => {
    onChange({ ...data, deleted: true });
  };

  const onFormChange = (values: any): void => {
    const groupData = data.all || data.any;
    onChange({ id: data.id, [values.allAny]: groupData });
  };

  return (
    <FormContainer isMain={isMain}>
      <GroupOptions>
        <FormComponent
          fields={[
            {
              key: 'allAny',
              label: 'All/Any',
              type: 'select',
              validate: 'onChange',
              fieldProps: {
                options: ALL_ANY_VALUES,
                style: { width: 100 }
              },
              valueFormat: (v): SelectValueType | null =>
                ALL_ANY_VALUES.find((item) => item.value === v) || null,
              onUpdateFormat: (item): string => item.value
            }
          ]}
          gutterSize="small"
          initialData={initialData}
          onChange={onFormChange}
          showSave={false}
        />
        <ButtonContainer>
          <Button icon="add" background="greyLight" onClick={onAddGroupClick}>
            Group
          </Button>
          <Button icon="add" background="greyLight" onClick={onAddRuleClick}>
            Rule
          </Button>
          {!isMain && (
            <Button
              icon="delete"
              background="greyLight"
              onClick={onRemoveGroup}
            />
          )}
        </ButtonContainer>
      </GroupOptions>

      {fields.length > 0 && (
        <GroupContainer>
          {fields.map((field) => {
            const isGroup = field.all || field.any;
            const ChildComponent = isGroup ? FormGroup : FormRule;

            return (
              <ChildComponent
                key={field.id}
                data={field}
                facts={facts}
                FormComponent={FormComponent}
                onChange={onChange}
              />
            );
          })}
        </GroupContainer>
      )}
    </FormContainer>
  );
};
