import React from 'react';
import {
  MdAdd,
  MdArrowDropDown,
  MdBugReport,
  MdCallSplit,
  MdCheck,
  MdChevronRight,
  MdClose,
  MdDashboard,
  MdDelete,
  MdEdit,
  MdError,
  MdExitToApp,
  MdFileUpload,
  MdFlashOn,
  MdFolder,
  MdGroup,
  MdInfo,
  MdKeyboardArrowUp,
  MdLens,
  MdMemory,
  MdMenu,
  MdMessage,
  MdMoreVert,
  MdPlayArrow,
  MdQuestionAnswer,
  MdSearch,
  MdSend,
  MdSettingsBackupRestore,
  MdTimeline,
  MdVisibility,
  MdWarning,
  MdWidgets
} from 'react-icons/md';
import { FaBoxOpen, FaPlug } from 'react-icons/fa';
import { FiAtSign } from 'react-icons/fi';

import { ColorName, colors } from '../../theme';

interface SVGProps {
  size?: string;
  color?: string;
}

export interface IconProps extends React.SVGAttributes<SVGSVGElement> {
  name: IconName;
  size?: IconSize;
  color?: ColorName;
}

export type IconName =
  | 'add'
  | 'alert'
  | 'at'
  | 'bug'
  | 'caretDown'
  | 'check'
  | 'chevronRight'
  | 'chevronUp'
  | 'circle'
  | 'close'
  | 'conversation'
  | 'dashboard'
  | 'delete'
  | 'dots'
  | 'edit'
  | 'emptyBox'
  | 'flash'
  | 'folder'
  | 'info'
  | 'logout'
  | 'memory'
  | 'menu'
  | 'message'
  | 'play'
  | 'plug'
  | 'restore'
  | 'search'
  | 'send'
  | 'split'
  | 'timeline'
  | 'upload'
  | 'users'
  | 'visibility'
  | 'warning'
  | 'widgets';
type IconSize = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';

type Icons = { [key in IconName]: React.FC<SVGProps> };

export const icons: Icons = {
  add: MdAdd,
  alert: MdError,
  at: FiAtSign,
  bug: MdBugReport,
  caretDown: MdArrowDropDown,
  check: MdCheck,
  chevronRight: MdChevronRight,
  chevronUp: MdKeyboardArrowUp,
  circle: MdLens,
  close: MdClose,
  conversation: MdQuestionAnswer,
  dashboard: MdDashboard,
  delete: MdDelete,
  dots: MdMoreVert,
  edit: MdEdit,
  emptyBox: FaBoxOpen,
  flash: MdFlashOn,
  folder: MdFolder,
  info: MdInfo,
  logout: MdExitToApp,
  memory: MdMemory,
  menu: MdMenu,
  message: MdMessage,
  play: MdPlayArrow,
  plug: FaPlug,
  restore: MdSettingsBackupRestore,
  search: MdSearch,
  send: MdSend,
  split: MdCallSplit,
  timeline: MdTimeline,
  upload: MdFileUpload,
  users: MdGroup,
  visibility: MdVisibility,
  warning: MdWarning,
  widgets: MdWidgets
};

const iconSizes: { [key in IconSize]: string } = {
  xsmall: '12px',
  small: '18px',
  medium: '24px',
  large: '36px',
  xlarge: '48px',
  xxlarge: '72px'
};

export const Icon: React.FC<IconProps> = ({
  name,
  size = 'medium',
  color = 'greyDark',
  ...props
}) => {
  const IconComponent = icons[name];
  const iconSize = iconSizes[size];
  const iconColor = colors[color];

  return <IconComponent {...props} color={iconColor} size={iconSize} />;
};
