import React from 'react';
import styled, { css } from 'styled-components';
import { colors, fonts } from '../../theme';
import { Icon } from '../Icon';

export interface StepperProps extends React.HTMLAttributes<HTMLDivElement> {
  steps: Pick<StepProps, 'label'>[];
  activeStep?: number;
}

interface StepProps {
  stepNumber: number;
  isActive: boolean;
  isCompleted: boolean;
  label?: string;
}

interface StepItemProps {
  isActive: boolean;
  isCompleted: boolean;
}

const StyledStepper = styled.div`
  font-family: ${fonts.default};
  color: ${colors.greyDark};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
`;

const StyledStep = styled.div`
  flex: 1;
  position: relative;
`;

const StepConnector = styled.div<StepItemProps>(
  (props) => css`
    flex: 1 1 auto;
    top: 16px;
    left: calc(-50% + 22px);
    right: calc(50% + 22px);
    position: absolute;
    border-top: 2px solid ${colors.offWhite};
    border-radius: 1px;

    ${(props.isActive || props.isCompleted) &&
      css`
        border-top-color: ${colors.primary};
      `}
  `
);

const StyledStepContent = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const StyledStepNumber = styled.div<StepItemProps>(
  (props) => css`
    width: 32px;
    height: 32px;
    background: ${colors.offWhite};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    box-sizing: border-box;

    ${props.isActive &&
      css`
        border: 2px solid ${colors.primary};
      `}

    ${props.isCompleted &&
      css`
        background: ${colors.primary};
        color: ${colors.white};
      `}
  `
);

const StyledStepLabel = styled.div<StepItemProps>(
  (props) => css`
    margin-top: 16px;
    text-align: center;
    color: ${colors.greyLight};

    ${(props.isActive || props.isCompleted) &&
      css`
        color: ${colors.greyDark};
        font-weight: 500;
      `}
  `
);

const Step: React.FC<StepProps> = ({
  stepNumber,
  label,
  isActive,
  isCompleted
}) => (
  <StyledStep>
    {stepNumber > 1 && (
      <StepConnector isActive={isActive} isCompleted={isCompleted} />
    )}
    <StyledStepContent>
      <StyledStepNumber isActive={isActive} isCompleted={isCompleted}>
        {isCompleted ? (
          <Icon name="check" color="white" size="small" />
        ) : (
          stepNumber
        )}
      </StyledStepNumber>
      {label && (
        <StyledStepLabel isActive={isActive} isCompleted={isCompleted}>
          {label}
        </StyledStepLabel>
      )}
    </StyledStepContent>
  </StyledStep>
);

export const Stepper: React.FC<StepperProps> = ({
  steps,
  activeStep = 1,
  ...props
}) => (
  <StyledStepper {...props}>
    {steps.map((step, index) => {
      const stepNumber = index + 1;
      const isActive = activeStep === index;
      const isCompleted = activeStep > index;

      return (
        <Step
          key={stepNumber}
          stepNumber={stepNumber}
          isActive={isActive}
          isCompleted={isCompleted}
          label={step.label}
        />
      );
    })}
  </StyledStepper>
);
