import React from 'react';
import { default as RcSlider } from 'rc-slider';
import styled from 'styled-components';
import { rgba } from 'polished';

import { colors, fonts } from '../../theme';

type HtmlDivProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

export interface SliderProps extends HtmlDivProps {
  value: number;
  min?: number;
  max?: number;
  label: string;
  disabled?: boolean;
  step?: number;
  onChange: (value: number) => void;
}

const StyledWrapper = styled.div`
  width: 288px;
  max-width: 100%;
`;

const StyledLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

const StyledLabel = styled.label`
  color: ${colors.greyDark};
  font-family: ${fonts.default};
  font-size: 12px;
`;

const StyledValue = styled.span`
  color: ${colors.greyDark};
  font-family: ${fonts.default};
  font-size: 14px;
  font-weight: bold;
`;

const StyledSlider = styled(RcSlider)`
  height: 12px;

  .rc-slider-rail,
  .rc-slider-track,
  .rc-slider-step {
    height: 2px;
  }

  .rc-slider-rail {
    background: ${colors.offWhite};
  }

  &.rc-slider-disabled {
    background: transparent;
    opacity: 0.6;
  }

  .rc-slider-handle {
    border: none;
    width: 12px;
    height: 12px;
  }

  .rc-slider-handle,
  .rc-slider-track,
  &.rc-slider-disabled .rc-slider-handle,
  &.rc-slider-disabled .rc-slider-dot,
  &.rc-slider-disabled .rc-slider-track {
    background: ${colors.primary};
  }

  &:not(.rc-slider-disabled) {
    .rc-slider-handle {
      transition: box-shadow 0.2s ease;
      will-change: box-shadow;

      &:focus:not(.rc-slider-handle-click-focused),
      &:active {
        box-shadow: 0 0 0 6px ${rgba(colors.primary, 0.35)};
      }
    }
  }
`;

export const Slider: React.FC<SliderProps> = ({
  value,
  min,
  max,
  label,
  disabled,
  step,
  onChange,
  ...props
}) => (
  <StyledWrapper {...props}>
    <StyledLabelWrapper>
      <StyledLabel>{label}</StyledLabel>
      <StyledValue>{value}</StyledValue>
    </StyledLabelWrapper>
    <StyledSlider
      min={min}
      max={max}
      value={value}
      disabled={disabled}
      step={step}
      onChange={onChange}
    />
  </StyledWrapper>
);
