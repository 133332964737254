import React, { Fragment, useMemo } from 'react';
import styled, { css } from 'styled-components';
import {
  FocusEventHandler,
  IndicatorProps,
  InputProps as ReactSelectInputProps,
  Props as ReactSelectProps,
  MenuProps,
  OptionProps,
  OptionTypeBase
} from 'react-select';

import { colors, fonts } from '../../theme';
import { InputBase, inputFocusStyle } from '../Input/InputBase';
import { Icon } from '../Icon';
import { Input } from '../Input';
import { SelectValueType } from './index';

interface InputProps extends ReactSelectInputProps {
  disabled?: boolean;
  fullWidth?: boolean;
  label: string;
  menuIsOpen?: boolean;
  multiple?: boolean;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  value: SelectValueType;
}

interface EditableInputProps extends InputProps {
  selectProps: ReactSelectProps<OptionTypeBase>;
  onChange?: any;
}

interface StyledOptionProps {
  isFocused?: boolean;
  isSelected?: boolean;
}

const inputBaseValueClassName = 'rui-select-input-value';

const StyledInputBase = styled(InputBase)(
  (props) => css`
  ${!props.isDisabled &&
    css`
      cursor: pointer;

      &:focus {
        ${inputFocusStyle}
      }
    `}

  .${inputBaseValueClassName} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 36px;
  }
`
);

const StyledEditableInput = styled(Input)(
  (props) => css`
    
  ${!props.disabled &&
    css`
      cursor: pointer;

      &:focus {
        ${inputFocusStyle}
      }
    `}

  .${inputBaseValueClassName} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 36px;
  }
`
);

const StyledMenu = styled.div`
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(197, 197, 197, 0.6);
  margin-top: 2px;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 10;
`;

const StyledOption = styled.div<StyledOptionProps>(
  (props) => css`
    color: ${colors[!props.isSelected ? 'greyDark' : 'primary']};
    font-family: ${fonts.default};
    line-height: 1.4;
    font-size: 16px;
    padding: 14px 16px;
    cursor: pointer;
    outline: none;
    position: relative;

    &:hover {
      background: ${colors.offWhite2};
    }

    ${props.isFocused &&
      css`
        background: ${colors.offWhite2};
      `}
  `
);

const StyledCaretDown = styled((
  { isOpen, ...rest } // eslint-disable-line @typescript-eslint/no-unused-vars
) => <Icon {...rest} name="caretDown" />).attrs((props) => ({
  style: {
    transform: `rotate(${!props.isOpen ? 0 : 180}deg)`
  }
}))`
  position: absolute;
  right: 10px;
  bottom: 16px;
  transition: transform 0.15s ease-out;
  will-change: transform;
  cursor: pointer;
`;

const StyledOptionIcon = styled(({ isFocused, ...rest }) => (
  <Icon
    {...rest}
    color={isFocused ? 'red' : 'primary'}
    name={isFocused ? 'close' : 'check'}
    size="small"
  />
))`
  position: absolute;
  right: 10px;
  top: 16px;
  cursor: pointer;
`;

const StyledGroupHeading = styled.div`
  padding: 8px 16px;
  color: ${colors.greyLight};
  background-color: ${colors.offWhite};
  font-family: ${fonts.default};
  font-size: 14px;
  font-weight: 600;
`;

export const GroupHeading: React.FC = ({ children }) => (
  <StyledGroupHeading>{children}</StyledGroupHeading>
);

export const FixedInput: React.FC<InputProps> = ({
  disabled,
  fullWidth,
  innerRef,
  label,
  menuIsOpen,
  multiple,
  onBlur,
  onFocus,
  value
}) => {
  const displayValue = useMemo(() => {
    if (multiple && value && value.length) {
      return value.length === 1
        ? value[0].label
        : `${value.length} Selected Items`;
    }
    return (value && value.label) || '';
  }, [value, multiple]);

  const innerInputProps = {
    as: 'div',
    children: displayValue,
    className: inputBaseValueClassName
  };

  return (
    <StyledInputBase
      fullWidth={fullWidth}
      isDisabled={disabled}
      isFocused={menuIsOpen}
      label={label}
      value={displayValue}
      inputProps={innerInputProps}
      // @ts-ignore
      tabIndex={0}
      // @ts-ignore
      ref={innerRef}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export const EditableInput: React.FC<EditableInputProps> = ({
  disabled,
  fullWidth,
  innerRef,
  label,
  multiple,
  onBlur,
  onFocus,
  onChange,
  selectProps,
  value
}) => {
  const { inputValue } = selectProps;

  const displayValue = useMemo(() => {
    if (multiple && value && value.length) {
      return value.length === 1
        ? value[0].label
        : `${value.length} Selected Items`;
    }

    return inputValue;
  }, [value, multiple, inputValue]);

  return (
    <StyledEditableInput
      fullWidth={fullWidth}
      disabled={disabled}
      label={label}
      value={displayValue}
      // @ts-ignore
      tabIndex={0}
      // @ts-ignore
      ref={innerRef}
      onBlur={onBlur}
      inner
      onFocus={onFocus}
      onChange={onChange}
    />
  );
};

export const Menu: React.FC<MenuProps<any, any>> = ({
  innerProps,
  children
}) => <StyledMenu {...innerProps}>{children}</StyledMenu>;

export const Option: React.FC<OptionProps<any, any>> = ({
  children,
  innerProps,
  innerRef,
  isFocused,
  isMulti,
  isSelected
}) => (
  <StyledOption
    {...innerProps}
    isFocused={isFocused}
    isSelected={isSelected}
    ref={innerRef}
  >
    {children}
    {isSelected && isMulti && <StyledOptionIcon isFocused={isFocused} />}
  </StyledOption>
);

export const DropdownIndicator: React.FC<IndicatorProps<any, any>> = ({
  innerProps,
  selectProps
}) => <StyledCaretDown {...innerProps} isOpen={selectProps.menuIsOpen} />;

export const FragmentPassThrough: React.FC<{ children: React.ReactNode }> = ({
  children
}) => <Fragment>{children}</Fragment>;

export const NullElement: React.ReactNode = () => null;
