import * as yup from "yup";

export default yup.object().shape({
  name: yup.string().required().label("Name"),
  is_active: yup.mixed().oneOf([0, 1]).label("Active"),
  record_sync_stt: yup.mixed().oneOf([0, 1]).label("Record sync stt"),
  language_customization_id_a: yup
    .string()
    .nullable()
    .label("Language Customization ID a"),
  language_customization_id_b: yup
    .string()
    .nullable()
    .label("Language Customization ID b"),
  timeformat: yup
      .mixed()
      .oneOf(["12-hour", "24-hour"])
      .required()
      .label("Time Format"),
  active_language_customization: yup
    .mixed()
    .oneOf(["A", "B"])
    .required()
    .label("Active Language Customization"),
  instance_id: yup.number().required().label("Instance"),
  available_licenses: yup
    .number()
    .nullable()
    .positive()
    .required()
    .label("Available Licences"),
  oauth_configured: yup.mixed().oneOf([0, 1]).label("OAuth Configured"),
  sso_configured: yup.mixed().oneOf([0, 1]).label("Open in a new window"),
});
