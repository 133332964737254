import React from 'react';
import styled, { css } from 'styled-components';

import { HEADER_NAV_HEIGHT } from '../HeaderNav';
import { SIDEBAR_WIDTH } from '../Sidebar';

export interface MainWrapProps extends React.HTMLAttributes<HTMLDivElement> {
  padLeft?: boolean;
  padTop?: boolean;
}

const PAGE_PADDING = 10;

const defaultPadding = `${PAGE_PADDING}px`;
const loggedInTopPadding = `${HEADER_NAV_HEIGHT + PAGE_PADDING}px`;
const loggedInLeftPadding = `${SIDEBAR_WIDTH + PAGE_PADDING}px`;

const Main = styled.main<{ padLeft?: boolean; padTop?: boolean }>(
  ({ padLeft = false, padTop = false }) => css`
    padding-left: ${padLeft ? loggedInLeftPadding : defaultPadding};
    padding-top: ${padTop ? loggedInTopPadding : defaultPadding};
    padding-right: ${defaultPadding};
    padding-bottom: ${defaultPadding};
    transition: padding-left 0.2s ease;
  `
);

export const MainWrap: React.FC<MainWrapProps> = (props) => <Main {...props} />;
