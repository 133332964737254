import React, { Fragment } from 'react';

export interface AccordionProps {
  content: React.ReactNode | (() => React.ReactNode);
  disabled?: boolean;
  isOpen: boolean;
  label: React.ReactNode | ((isOpen: boolean) => React.ReactNode);
  onSelect: () => void;
}
interface AccordionListItem
  extends Omit<AccordionProps, 'isOpen' | 'onSelect'> {
  id: string;
}

export interface AccordionListProps {
  activeAccordions: string[];
  items: AccordionListItem[];
  onAccordionSelect: (id: string, isOpen: boolean) => void;
}

export const Accordion: React.FC<AccordionProps> = ({
  content,
  disabled,
  isOpen,
  label,
  onSelect
}) => {
  const onAccordionToggle = (): void => {
    if (!disabled) onSelect();
  };

  return (
    <Fragment>
      <div
        onClick={onAccordionToggle}
        onKeyDown={(e): void => {
          if (e.key === 'Enter') {
            onAccordionToggle();
          }
        }}
        role="button"
        tabIndex={!disabled ? 0 : -1}
      >
        {typeof label === 'function' ? label(isOpen) : label}
      </div>
      {isOpen && (typeof content === 'function' ? content() : content)}
    </Fragment>
  );
};

export const AccordionList: React.FC<AccordionListProps> = ({
  activeAccordions = [],
  items,
  onAccordionSelect
}) => (
  <Fragment>
    {items.map(({ id, ...accordionProps }) => {
      const isOpen = activeAccordions.includes(id);

      return (
        <Accordion
          key={id}
          isOpen={isOpen}
          onSelect={(): void => onAccordionSelect(id, isOpen)}
          {...accordionProps}
        />
      );
    })}
  </Fragment>
);
