import React from 'react';
import styled from 'styled-components';
import { colors, fonts, cardStyle } from '@rollioforce/rollio-ui';

import { Form, FormProps, FormFields } from '../Form';

export interface LoginWrapProps extends React.HTMLAttributes<HTMLDivElement> {
  form?: Partial<FormProps>;
  logoImage?: string;
  titleLabel?: string;
}

const OuterWrap = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BoxWrap = styled.div`
  ${cardStyle}
  width: 320px;
  max-width: 100%;
  padding: 30px 20px 20px;
`;

const FormHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const Logo = styled.img`
  width: 51px;
  height: 50px;
  margin-right: 10px;
`;

const Title = styled.h1`
  font-family: ${fonts.default};
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: ${colors.primary};
`;

const DEFAULT_FIELDS: FormFields = [
  {
    type: 'input',
    key: 'user',
    label: 'Username'
  },
  {
    type: 'input',
    key: 'password',
    label: 'Password',
    fieldProps: {
      type: 'password'
    }
  }
];

export const LoginWrap: React.FC<LoginWrapProps> = ({
  form = {},
  logoImage,
  titleLabel = 'Rollio Login',
  ...props
}) => {
  const {
    fields = DEFAULT_FIELDS,
    saveButtonLabel = 'Sign In',
    ...formRest
  } = form;

  return (
    <OuterWrap {...props}>
      <BoxWrap>
        <FormHeader>
          <Logo src={logoImage} />
          <Title>{titleLabel}</Title>
        </FormHeader>

        <Form saveButtonLabel={saveButtonLabel} fields={fields} {...formRest} />
      </BoxWrap>
    </OuterWrap>
  );
};
