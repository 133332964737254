import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { colors, ColorName } from '../../theme';

interface Props {
  dark?: boolean;
  small?: boolean;
  color?: ColorName;
}

interface IndicatorProps {
  borderColor: string;
  small?: boolean;
}

const indicatorPartClassName = 'rui-button-loadingIndicator-part';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SIZE = 20;
const SIZE_SMALL = 16;

const LoadingIndicatorWrap = styled.div<IndicatorProps>(
  ({ borderColor, small }) => {
    const size = !small ? SIZE : SIZE_SMALL;
    const offset = size / 2;

    return css`
      width: ${size}px;
      height: ${size}px;
      position: absolute;
      left: calc(50% - ${offset}px);
      top: calc(50% - ${offset}px);
      animation: ${rotate} 4s linear infinite;
      will-change: transition;

      .${indicatorPartClassName} {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px solid transparent;
        border-top-color: ${borderColor};
        border-radius: 50%;
        animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        will-change: transition;

        &:nth-child(1) {
          animation-delay: -0.45s;
        }

        &:nth-child(2) {
          animation-delay: -0.3s;
        }

        &:nth-child(3) {
          animation-delay: -0.15s;
        }
      }
    `;
  }
);

export const LoadingIndicator: React.FC<Props> = ({ dark, small, color }) => {
  const innerColor = colors[dark ? 'greyLight' : color || 'white'];

  return (
    <LoadingIndicatorWrap borderColor={innerColor} small={small}>
      <div className={indicatorPartClassName} />
      <div className={indicatorPartClassName} />
      <div className={indicatorPartClassName} />
      <div className={indicatorPartClassName} />
    </LoadingIndicatorWrap>
  );
};
