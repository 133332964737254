import React from 'react';
import styled from 'styled-components';

import { colors, fonts } from '../../theme';
import { cardStyle } from '../../theme/utils';
import { Button } from '../Button';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: any;
  title: string;
  description: string;
  buttonLabel: string;
  onButtonClick: () => void;
}

const StyledCard = styled.div`
  ${cardStyle};
  width: 530px;
  max-width: 100%;
  padding: 10px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledHeader = styled.h5`
  font-family: ${fonts.default};
  font-size: 24px;
  color: ${colors.greyDark};
  font-weight: 600;
  margin: 0 0 10px;
`;

const StyledDescription = styled.p`
  font-family: ${fonts.default};
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: ${colors.greyDark};
  margin: 0 0 10px;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
`;

export const Card: React.FC<CardProps> = ({
  title,
  description,
  buttonLabel,
  onButtonClick,
  ...props
}) => (
  <StyledCard {...props}>
    <StyledHeader>{title}</StyledHeader>
    <StyledDescription>{description}</StyledDescription>
    <StyledButton onClick={onButtonClick}>{buttonLabel}</StyledButton>
  </StyledCard>
);
