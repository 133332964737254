import React from 'react';
import styled from 'styled-components';

import { colors, fonts } from '../../theme';

export interface LabelBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: any;
  label: string;
  children: React.ReactNode;
}

const StyledLabelBox = styled.div`
  border: 2px solid ${colors.offWhite2};
  padding: 18px 13px;
  border-radius: 4px;
  position: relative;
  margin-top: 6px;
`;

const StyledLabel = styled.label`
  font-family: ${fonts.default};
  color: ${colors.greyDark};
  font-size: 12px;
  position: absolute;
  line-height: 1;
  background: ${colors.white};
  left: 7px;
  top: -7px;
  padding: 0 3px;
`;

export const LabelBox: React.FC<LabelBoxProps> = ({
  label,
  children,
  ...props
}) => (
  <StyledLabelBox {...props}>
    <StyledLabel>{label}</StyledLabel>
    {children}
  </StyledLabelBox>
);
