import React from 'react';
import styled from 'styled-components';
import { Button, colors, SelectValueType } from '@rollioforce/rollio-ui';

import { FormComponentType } from '../../index';

export interface FormRuleProps {
  data: any;
  facts?: SelectValueType[];
  FormComponent: FormComponentType;
  onChange: (values: any) => any;
}

const OPERATOR_VALUES = [
  {
    value: '=',
    label: 'Equal'
  },
  {
    value: '!=',
    label: 'Not equal'
  },
  {
    value: '>',
    label: 'Greater than'
  },
  {
    value: '>=',
    label: 'Greater than or equal'
  },
  {
    value: '<',
    label: 'Less than'
  },
  {
    value: '<=',
    label: 'Less than or equal'
  },
  {
    value: 'prompted',
    label: 'Prompted'
  }
];

const FormContainer = styled.div`
  position: relative;
  margin: 17px;
  background-color: #ffffff;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: -14px;
    width: 12px;
    height: calc(50% + 14px);
    border-style: solid;
    border-color: ${colors.offWhite2};
  }
  &:before {
    top: -13px;
    border-width: 0 0 2px 2px;
  }
  &:after {
    top: calc(50%);
    border-width: 0 0 0 2px;
  }
  &:first-child::before {
    top: -17px;
    height: calc(50% + 17px);
  }
  &:last-child {
    &::before {
      border-radius: 0 0 0 4px;
    }
    &::after {
      display: none;
    }
  }
`;

const GroupOptions = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonContainer = styled.div`
  height: 56px;
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;
  }
`;

export const FormRule: React.FC<FormRuleProps> = ({
  data,
  facts,
  FormComponent,
  onChange
}) => {
  const initialData = {
    factName: data.fact,
    operator: data.operator,
    value: data.value
  };

  const onFormChange = (values: any): void => {
    const ruleData = {
      id: data.id,
      fact: values.factName,
      operator: values.operator,
      value: values.value
    };
    onChange(ruleData);
  };

  const onRemoveRule = (): void => {
    onChange({ ...data, deleted: true });
  };

  return (
    <FormContainer>
      <GroupOptions>
        <FormComponent
          fields={[
            {
              key: 'rule',
              type: 'row',
              fields: [
                {
                  key: 'factName',
                  label: 'Fact',
                  type: 'select',
                  validate: 'onChange',
                  fieldProps: {
                    options: facts,
                    style: { width: 200 }
                  },
                  valueFormat: (v): SelectValueType | null =>
                    facts?.find((item) => item.value === v) || null,
                  onUpdateFormat: (item): string => item.value
                },
                {
                  key: 'operator',
                  label: 'Operator',
                  type: 'select',
                  validate: 'onChange',
                  fieldProps: {
                    options: OPERATOR_VALUES,
                    style: { width: 120 }
                  },
                  valueFormat: (v): SelectValueType | null =>
                    OPERATOR_VALUES.find((item) => item.value === v) || null,
                  onUpdateFormat: (item): string => item.value
                },
                {
                  key: 'value',
                  label: 'Value',
                  type: 'input',
                  validate: 'onChange',
                  fieldProps: {
                    style: { width: 150 }
                  }
                }
              ]
            }
          ]}
          gutterSize="small"
          initialData={initialData}
          onChange={onFormChange}
          showSave={false}
        />
        <ButtonContainer>
          <Button icon="delete" background="greyLight" onClick={onRemoveRule} />
        </ButtonContainer>
      </GroupOptions>
    </FormContainer>
  );
};
