/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, Select, colors, fonts } from '@rollioforce/rollio-ui';
import { IconName } from '@rollioforce/rollio-ui/dist/types/components/Icon';

export interface SidebarLink {
  active?: boolean;
  icon: IconName;
  id: string;
  label: React.ReactNode;
}

export interface SidebarSelect {
  onChange?: (value: any) => void;
  label: string;
  options?:
    | { label: string; value: string }[]
    | { label: string; options: { label: any; value: any }[] }[];
  tabIndex?: number;
  value?: { label: string; value: string };
  disabled?: boolean;
}

export type SidebarLinks = SidebarLink[];

type renderLinkFn = (id: string, linkInner: React.ReactNode) => React.ReactNode;

export interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  copyrightLabel?: string;
  greetingLabel?: string;
  isVisible?: boolean;
  linkItems?: SidebarLinks;
  logoImage?: string;
  logoutLabel?: string;
  onLogoutClick?: () => void;
  renderLink?: renderLinkFn;
  select?: SidebarSelect;
  titleLabel: string;
  usernameLabel?: string;
}

export const SIDEBAR_WIDTH = 250;

const SidebarWrap = styled.aside<{ isVisible?: boolean }>(
  ({ isVisible = true }) => css`
    display: flex;
    flex-direction: column;
    width: ${SIDEBAR_WIDTH}px;
    background: ${colors.white};
    box-shadow: 1px 0 3px 0 rgba(197, 197, 197, 0.4);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    backface-visibility: hidden;
    z-index: 15;
    transform: translateX(${isVisible ? 0 : `-${SIDEBAR_WIDTH}px`});
    transition: transform 0.2s ease;
    font-family: ${fonts.default};
  `
);

const SidebarDivider = styled.span`
  display: block;
  background: ${colors.offWhite};
  border-radius: 0.5px;
  height: 1px;
  width: calc(100% - 10px);
  margin-left: 5px;
`;

const StyledSelect = styled(Select)`
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
  input {
    border-bottom: 0;

    :not(:focus) {
      color: ${colors.primary};
      font-weight: 600;
    }
  }
`;

const SidebarHeader = styled.header`
  padding: 20px 10px 14px;
  display: flex;
`;

const RollioLogo = styled.img`
  width: 51px;
  height: 50px;
  margin-right: 10px;
`;

const HeaderInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const SidebarTitle = styled.strong`
  display: block;
  font-weight: 700;
  font-size: 16px;
  color: ${colors.primary};
  margin-bottom: 5px;
`;

const WelcomeMessage = styled.div`
  background: #ededed;
  border-radius: 2px 9999px 9999px 9999px;
  font-size: 12px;
  padding: 4px 16px 5px 8px;
  color: ${colors.greyLight};
  display: flex;
`;

const WelcomeMessageEmoji = styled.div`
  margin-right: 6px;
  width: 14px;
`;

const WelcomeMessageText = styled.div`
  flex: 1;
  padding-top: 3px;
`;

const UserName = styled.span`
  font-weight: 700;
  color: ${colors.greyDark};
`;

const MenuItems = styled.nav`
  flex: 1;
  padding: 24px;
`;

const MenuItemWrap = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
`;

const MenuItemInner = styled.span`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const MenuItemIcon = styled(Icon)`
  margin-right: 22px;
`;

const MenuItemLabel = styled.span<{ isActive: boolean }>(
  ({ isActive }) => css`
    font-size: 17px;
    color: ${!isActive ? colors.greyLight : colors.greyDark};
    opacity: ${!isActive ? 0.8 : 1};
    transition: opacity 0.2s ease;

    ${isActive &&
    css`
      font-weight: 600;
    `}

    ${MenuItemInner}:hover & {
      opacity: 1;
    }
  `
);

const SidebarFooter = styled.footer`
  padding: 10px;
`;

const LogoutLink = styled.button`
  font-weight: 600;
  font-size: 12px;
  color: ${colors.greyDark};
  appearance: none;
  border: 0;
  padding: 2px 6px 2px 0;
  background: transparent;
  margin-bottom: 15px;
  cursor: pointer;
`;

const LogoutLinkInner = styled.div`
  display: flex;
  align-items: center;
`;

const LogoutIcon = styled(Icon).attrs(() => ({
  color: 'greyDark',
  name: 'logout'
}))`
  margin-right: 5px;
`;

const CopyrightLabel = styled.span`
  display: block;
  font-size: 8px;
  color: ${colors.greyLight};
`;

const printLinkItems = (
  linksItems: SidebarLink[] | undefined,
  renderLink: renderLinkFn,
  select: SidebarSelect | undefined
): React.ReactNode[] => {
  const processedLinkItems = linksItems || [];

  const sidebarLinkItems = processedLinkItems.map(
    ({ active = false, icon, id, label }) => {
      const linkInner = (
        <MenuItemInner>
          <MenuItemIcon name={icon} color={!active ? 'greyLight' : 'primary'} />
          <MenuItemLabel isActive={active}>{label}</MenuItemLabel>
        </MenuItemInner>
      );

      return <MenuItemWrap key={id}>{renderLink(id, linkInner)}</MenuItemWrap>;
    }
  );

  if (select) {
    const { label, options, value, onChange, disabled, tabIndex = 1 } = select;

    const selectComponent = (
      <MenuItemWrap key={label}>
        <StyledSelect
          options={options}
          label={label}
          isSearchable
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </MenuItemWrap>
    );

    sidebarLinkItems.splice(tabIndex, 0, selectComponent);
  }

  return sidebarLinkItems;
};

export const Sidebar: React.FC<SidebarProps> = ({
  copyrightLabel = '© 2020 Rollio',
  greetingLabel = 'Welcome, ',
  isVisible,
  linkItems,
  logoImage,
  logoutLabel = 'Logout',
  onLogoutClick,
  renderLink = (_, l): React.ReactNode => l,
  titleLabel,
  select,
  usernameLabel,
  ...props
}) => {
  const getAllLinkItems: React.ReactNode[] = React.useMemo(
    () => printLinkItems(linkItems, renderLink, select),
    [linkItems, select, renderLink]
  );

  return (
    <SidebarWrap {...props} isVisible={isVisible}>
      <SidebarHeader>
        <RollioLogo src={logoImage} />
        <HeaderInner>
          {titleLabel && <SidebarTitle>{titleLabel}</SidebarTitle>}
          {greetingLabel && usernameLabel && (
            <WelcomeMessage>
              <WelcomeMessageEmoji>
                <span role="img" aria-label="wave">
                  👋
                </span>
              </WelcomeMessageEmoji>{' '}
              <WelcomeMessageText>
                {greetingLabel} <UserName>{usernameLabel}</UserName>
              </WelcomeMessageText>
            </WelcomeMessage>
          )}
        </HeaderInner>
      </SidebarHeader>
      <SidebarDivider />

      <MenuItems>{getAllLinkItems}</MenuItems>

      <SidebarDivider />

      <SidebarFooter>
        <LogoutLink onClick={onLogoutClick}>
          <LogoutLinkInner>
            <LogoutIcon />
            {logoutLabel}
          </LogoutLinkInner>
        </LogoutLink>
        <CopyrightLabel>{copyrightLabel}</CopyrightLabel>
      </SidebarFooter>
    </SidebarWrap>
  );
};
