import React, { useMemo, useState } from 'react';
import { useUID } from 'react-uid';

import { IconName } from '../Icon';
import { InputBase, NativeInputProps } from './InputBase';

export type InputRefType = HTMLInputElement;

export interface InputProps extends React.HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: IconName;
  id?: string;
  input?: NativeInputProps;
  label: string;
  name?: string;
  onChange: (event: React.ChangeEvent<InputRefType>) => void;
  type?: string;
  value?: string;
}

export const Input: React.FC<InputProps> = ({
  disabled,
  id,
  icon,
  input = {},
  label,
  name,
  onChange,
  type = 'text',
  value,
  ...props
}) => {
  const [isFocused, setFocus] = useState(false);
  const uuid = useUID();
  const inputId = id || `rui_input_${uuid}`;

  const inputProps = useMemo(
    () => ({
      ...input,
      onBlur: (): void => setFocus(false),
      onFocus: (): void => setFocus(true),
      onChange,
      name,
      type
    }),
    [input, name, onChange, type]
  );

  return (
    <InputBase
      {...props}
      icon={icon}
      id={inputId}
      isDisabled={disabled}
      isFocused={isFocused}
      label={label}
      value={value}
      inputProps={inputProps}
    />
  );
};
