import React from 'react';
import { Tab, Tabs as ReactTabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';

import { colors, fonts } from '../../theme';

export interface TabsProps extends React.HTMLAttributes<HTMLDivElement> {
  tabs: Array<{ label: string; content: React.ReactNode; disabled?: boolean }>;
  onTabSelect: (tabIndex: number) => void;
  activeTab: number;
}

const StyledTabList = styled(TabList)`
  display: block;
  list-style: none;
  border: none;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.offWhite};
  padding-left: 0;
`;

const selectedClassName = 'rui-tab--selected';
const disabledClassName = 'rui-tab--disabled';

const StyledTab = styled(Tab).attrs(() => ({
  disabledClassName,
  selectedClassName
}))`
  position: relative;
  display: inline-block;
  font-family: ${fonts.default};
  font-size: 16px;
  font-weight: 600;
  color: ${colors.greyLight};
  padding: 0 20px 12px 20px;
  cursor: pointer;
  border: 0;
  outline: none;

  &.${selectedClassName} {
    color: ${colors.primary};
    font-weight: 700;

    &::after {
      content: '';
      background: ${colors.primary};
      height: 1px;
      position: absolute;
      left: 20%;
      right: 20%;
      bottom: -1px;
    }
  }

  &.${disabledClassName} {
    opacity: 0.5;
  }
`;

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  onTabSelect,
  activeTab,
  ...props
}) => (
  <ReactTabs
    {...props}
    onSelect={(index): void => onTabSelect(index)}
    selectedIndex={activeTab}
  >
    <StyledTabList>
      {tabs.map((tab) => (
        <StyledTab disabled={tab.disabled} key={tab.label}>
          {tab.label}
        </StyledTab>
      ))}
    </StyledTabList>
    {tabs.map((tab) => (
      <TabPanel key={tab.label}>{tab.content}</TabPanel>
    ))}
  </ReactTabs>
);
