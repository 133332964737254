import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, colors } from '@rollioforce/rollio-ui';

interface Props {
  disableDown?: boolean;
  disableUp?: boolean;
  onDownClick: () => void;
  onUpClick: () => void;
}

interface SortButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  flip?: boolean;
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  transition: opacity 0.15s ease, visibility 0.15s ease;

  .rt-tr:not(:hover) & {
    opacity: 0;
    visibility: hidden;
  }
`;

const StyledIcon = styled(Icon).attrs(() => ({
  color: 'greyLight',
  name: 'caretDown'
}))`
  transition: color 0.2s ease;
  will-change: color;
`;

const Button = styled.button<{ flip: boolean }>(
  ({ flip }) => css`
    display: block;
    appearance: none;
    border: 0;
    background: none;
    margin: 0;
    padding: 0;
    line-height: 0;
    cursor: pointer;
    transform: rotate(${flip ? 180 : 0}deg);

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:not([disabled]):hover ${StyledIcon} {
      color: ${colors.primary} !important;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: default;
    }
  `
);

const SortButton: React.FC<SortButtonProps> = ({ flip = false, ...props }) => (
  <Button flip={flip} {...props}>
    <StyledIcon />
  </Button>
);

export const TableReorder: React.FC<Props> = ({
  disableDown = false,
  disableUp = false,
  onDownClick,
  onUpClick
}) => (
  <Wrap>
    <SortButton disabled={disableUp} flip onClick={onUpClick} />
    <SortButton disabled={disableDown} onClick={onDownClick} />
  </Wrap>
);
