import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Icon, IconName, IconProps } from '../Icon';
import { colors, fonts } from '../../theme';

interface StyledWrapperProps {
  fullWidth?: boolean;
  isFocused?: boolean;
}

interface StyledLabelProps {
  isDisabled?: boolean;
  isFocused?: boolean;
  isRaised?: boolean;
}

interface StyledInputProps {
  hasIcon?: boolean;
}

export type NativeInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export interface InputBaseProps<T>
  extends React.HTMLAttributes<HTMLDivElement> {
  fullWidth?: boolean;
  icon?: IconName;
  iconProps?: Omit<IconProps, 'name'>;
  id?: string;
  isDisabled?: boolean;
  isFocused?: boolean;
  inputProps?: T;
  label: string;
  labelProps?: React.HTMLAttributes<HTMLLabelElement>;
  value?: string;
}

interface InternalProps<T> extends InputBaseProps<T> {
  innerRef: React.Ref<HTMLDivElement>;
}

export const inputFocusStyle = css`
  background: ${colors.offWhite};

  &::after {
    left: 0;
    right: 0;
  }
`;

const StyledInputWrapper = styled.div<StyledWrapperProps>((props) => {
  const width = !props.fullWidth ? '280px' : '100%';

  return css`
    position: relative;
    font-family: ${fonts.default};
    background: ${colors.offWhite2};
    width: ${width};
    max-width: 100%;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    outline: none;
    transition: background 0.18s ease;
    will-change: background;

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      background-color: ${colors.primary};
      left: 50%;
      right: 50%;
      bottom: 0;
      transition: left 0.18s ease, right 0.18s ease;
      will-change: left, right;
    }

    ${props.isFocused && inputFocusStyle}
  `;
});

const StyledInput = styled.input<StyledInputProps>((props) => {
  const paddingRight = !props.hasIcon ? 12 : 52;

  return css`
    box-sizing: border-box;
    font-family: inherit;
    background: transparent;
    border: none;
    border-bottom: 1px solid ${colors.greyLight};
    outline: none;
    font-size: 16px;
    height: 56px;
    line-height: 41px;
    width: 100%;
    padding: 15px ${paddingRight}px 0 12px;
    box-shadow: none;
    color: ${colors.greyDark};
    caret-color: ${colors.primary};
    appearance: none;
    border-radius: 0;
    opacity: ${!props.disabled ? 1 : 0.7};
  `;
});

const StyledLabel = styled.label<StyledLabelProps>((props) => {
  const color = colors[!props.isFocused ? 'greyLight' : 'primary'];
  const transform = !props.isRaised
    ? 'translateY(-50%)'
    : 'translateY(-100%) scale(0.75);';
  const opacity = !props.isDisabled ? 1 : 0.6;
  const cursor = !props.isDisabled ? 'text' : 'default';

  return css`
    color: ${color};
    position: absolute;
    top: 50%;
    left: 12px;
    font-size: 16px;
    transition: transform 0.15s ease-out, color 0.15s ease-out;
    will-change: transform, color;
    transform: ${transform};
    transform-origin: 0 0;
    user-select: none;
    opacity: ${opacity};
    cursor: ${cursor};
  `;
});

const StyledIcon = styled(Icon)`
  pointer-events: none;
  position: absolute;
  top: calc(50% - 12px);
  right: 16px;
`;

const InputBaseComponent: React.FC<InternalProps<NativeInputProps>> = ({
  fullWidth,
  icon,
  iconProps = {
    color: 'greyLight'
  },
  id,
  innerRef,
  inputProps = {},
  isDisabled,
  isFocused,
  label,
  labelProps,
  value,
  ...props
}) => {
  const isRaised = isFocused || !!value;
  const hasIcon = icon && typeof icon === 'string';

  const inputComponentProps = {
    ...inputProps,
    value,
    id
  };

  return (
    <StyledInputWrapper
      {...props}
      ref={innerRef}
      fullWidth={fullWidth}
      isFocused={isFocused}
    >
      <StyledInput
        {...inputComponentProps}
        hasIcon={hasIcon}
        disabled={isDisabled}
      />
      <StyledLabel
        {...labelProps}
        isDisabled={isDisabled}
        isRaised={isRaised}
        isFocused={isFocused}
        htmlFor={id}
      >
        {label}
      </StyledLabel>
      {icon && hasIcon && <StyledIcon {...iconProps} name={icon} />}
    </StyledInputWrapper>
  );
};

export const InputBase = forwardRef<
  HTMLDivElement,
  InputBaseProps<NativeInputProps>
>((props, ref) => <InputBaseComponent {...props} innerRef={ref} />);

InputBase.displayName = 'InputBase';
