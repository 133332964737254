import React from 'react';
import styled, { css, SimpleInterpolation } from 'styled-components';
import { ColorName, colors, fonts } from '../../theme';

export interface ToolTipProps {
  children: React.ReactNode;
  background?: ColorName;
  textColor?: ColorName;
  titleColor?: ColorName;
  position?: string;
  title?: string;
  message: string;
}

interface StyledToolTipProps {
  title?: string;
  message: string;
  textColor?: ColorName;
  titleColor?: ColorName;
  background?: ColorName;
  position?: string;
}

type StyleGeneratorFn = (
  position: string,
  margin: string,
  backgroundColor: string
) => ReadonlyArray<SimpleInterpolation> | null;

type StyledToolBoxProps = {
  textColor: string;
  backgroundColor: string;
  positionStyles: any;
};

type StyledToolBoxTitleProps = {
  titleColor: string;
};

const generatePositionStyles: StyleGeneratorFn = (
  position,
  backgroundColor,
  margin
) => {
  switch (position) {
    case 'top':
      return css`
        bottom: calc(100% + ${margin});

        ::before {
          top: 100%;
          border-top-color: ${backgroundColor};
        }
      `;
    case 'right':
      return css`
        left: calc(100% + ${margin});
        top: 50%;
        transform: translateX(0) translateY(-50%);
        ::before {
          left: -6px;
          top: 50%;
          transform: translateY(-50%);
          border-right-color: ${backgroundColor};
        }
      `;
    case 'bottom':
      return css`
        top: calc(100% + ${margin});

        ::before {
          bottom: 100%;
          border-bottom-color: ${backgroundColor};
        }
      `;
    case 'left':
      return css`
        left: auto;
        right: calc(100% + ${margin});
        top: 50%;
        transform: translateX(0) translateY(-50%);

        ::before {
          left: auto;
          right: -12px;
          top: 50%;
          transform: translateX(0) translateY(-50%);
          border-left-color: ${backgroundColor};
        }
      `;
    default:
      return null;
  }
};

const ToolBox = styled.div<StyledToolBoxProps>(
  ({ textColor, backgroundColor, positionStyles }) => css`
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    display: inline-block;
    width: max-content;
    max-width: 250px;
    color: ${textColor};
    background: ${backgroundColor};
    font-size: 11px;
    font-family: ${fonts.default};
    text-align: left;
    z-index: 100;
    ::before {
      content: '';
      left: 50%;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid transparent 6px;
      margin-left: -6px;
    }
    ${positionStyles}
  `
);

const ToolBoxTitle = styled.h1<StyledToolBoxTitleProps>(
  ({ titleColor }) => css`
    font-size: 12px;
    color: ${titleColor};
    text-align: center;
  `
);

const StyledToolTipBox: React.FC<StyledToolTipProps> = ({
  message,
  position,
  title,
  titleColor,
  textColor,
  background
}) => {
  const backgroundColor = colors[background || 'greyDark'];
  const computedTextColor = colors[textColor || 'white'];
  const computedTitleColor = colors[titleColor || 'primary'];
  const computedPosition = position || 'bottom';
  const margin = '10px';
  const positionStyles = React.useMemo(
    () => generatePositionStyles(computedPosition, backgroundColor, margin),
    [backgroundColor, margin, computedPosition]
  );

  return (
    <ToolBox
      textColor={computedTextColor}
      backgroundColor={backgroundColor}
      positionStyles={positionStyles}
    >
      {title && (
        <ToolBoxTitle titleColor={computedTitleColor}>{title}</ToolBoxTitle>
      )}
      {message && <span>{message}</span>}
    </ToolBox>
  );
};

const ToolTipWrapper = styled.span`
  position: relative;
  display: inline-block;
  width: max-content;
  height: 100%;
`;

export const ToolTip: React.FC<ToolTipProps> = ({
  children,
  background,
  textColor,
  position,
  titleColor,
  title,
  message
}) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <ToolTipWrapper
      onMouseEnter={(): void => {
        setVisible(true);
      }}
      onMouseLeave={(): void => {
        setVisible(false);
      }}
    >
      {children}
      {visible && (
        <StyledToolTipBox
          title={title}
          message={message}
          textColor={textColor}
          background={background}
          titleColor={titleColor}
          position={position}
        />
      )}
    </ToolTipWrapper>
  );
};
