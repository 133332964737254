import React, { Fragment, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  LabelBox,
  Table,
  TableProps,
  colors,
  fonts
} from '@rollioforce/rollio-ui';
import { Columns } from '@rollioforce/rollio-ui/dist/types/components/Table';

import { TableReorder } from './TableReorder';
import { FormComponentType, FormFields, FormData } from '../../index';
import {
  all,
  append,
  filter,
  ifElse,
  includes,
  is,
  isEmpty,
  map,
  move,
  toggleListItem,
  without
} from '../../../../utils';

export interface FormTableProps extends Partial<Omit<TableProps, 'data'>> {
  allowRowReorder?: boolean;
  data: Array<any>;
  emptyStateAddNewLabel?: string;
  FormComponent: FormComponentType;
  isReadOnly?: boolean;
  keyField?: string;
  label?: string;
  miniFormAddLabel?: string;
  miniFormAddNewLabel?: string;
  miniFormCancelLabel?: string;
  miniFormFields?: FormFields;
  noDataText?: React.ReactNode;
  onChange: (data: any[]) => void;
  onMiniFormSubmit?: (data: any[], formData: FormData) => Array<any>;
}

const EmptyStateText = styled.span`
  font-family: ${fonts.default};
  color: ${colors.greyLight};
  font-size: 14px;
`;

const EmptyStateButton = styled(Button).attrs(() => ({
  small: true,
  dark: true
}))`
  pointer-events: all;
  display: block;
  margin: 20px auto 0;
`;

const TableFooter = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${colors.offWhite};
  padding-top: 10px;
`;

const MiniFormWrap = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  min-height: 56px;
`;

const FALLBACK_KEY_FIELD = 'name';

const areAllStrings = all(is(String));

export const FormTable: React.FC<FormTableProps> = ({
  allowRowReorder = false,
  columns = [],
  data = [],
  emptyStateAddNewLabel = 'Add New',
  FormComponent,
  isReadOnly,
  keyField,
  label,
  miniFormFields = [],
  miniFormAddLabel = 'Add',
  miniFormAddNewLabel = 'Add New',
  miniFormCancelLabel = 'Cancel',
  noDataText = '',
  onChange,
  onMiniFormSubmit,
  ...props
}) => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isMiniFormVisible, setIsMiniFormVisible] = useState(false);
  const [miniFormData, setMiniFormData] = useState<FormData>({});

  const isArrayOfStrings = useMemo(() => areAllStrings(data), [data]);

  const tableRows = useMemo(
    () =>
      ifElse(
        areAllStrings,
        map((s) => ({ [FALLBACK_KEY_FIELD]: s })),
        (d) => d
      )(data),
    [data]
  );

  const tableColumns = useMemo<Columns>(() => {
    const reorderColumn = {
      Header: '',
      maxWidth: 90,
      sortable: false,
      style: { marginLeft: 'auto' },
      headerStyle: { marginLeft: 'auto' },
      Cell: ({ index }: { index: number }): React.ReactNode => {
        const rowCount = data.length;

        if (rowCount === 1) {
          return null;
        }

        const isFirst = index === 0;
        const isLast = index === rowCount - 1;

        const moveField = (currentIndex: number, targetIndex: number): void => {
          const updatedGroupFields = move(currentIndex, targetIndex, data);

          onChange(updatedGroupFields);
        };

        return (
          <TableReorder
            disableDown={isLast}
            disableUp={isFirst}
            onDownClick={(): void => moveField(index, index + 1)}
            onUpClick={(): void => moveField(index, index - 1)}
          />
        );
      }
    };

    const extraColumns = allowRowReorder && !isReadOnly ? [reorderColumn] : [];

    if (isArrayOfStrings && !columns.length) {
      return [
        {
          Header: 'Name',
          accessor: FALLBACK_KEY_FIELD,
          cellVariant: 'header'
        },
        ...extraColumns
      ];
    }

    return [...columns, ...extraColumns];
  }, [allowRowReorder, columns, data, isArrayOfStrings, isReadOnly, onChange]);

  const tableKeyField = useMemo(
    () => (isArrayOfStrings ? FALLBACK_KEY_FIELD : keyField),
    [isArrayOfStrings, keyField]
  );

  const onRowToggle = useCallback(
    (keyProperty) =>
      setSelectedRows((keys) => toggleListItem(keyProperty, keys)),
    []
  );

  const onDeleteClick = useCallback(() => {
    onChange(
      ifElse(
        areAllStrings,
        without(selectedRows),
        filter((d: FormData) => includes(selectedRows, d[tableKeyField || '']))
      )(data)
    );
    setSelectedRows([]);
  }, [data, onChange, selectedRows, tableKeyField]);

  const enableMiniForm = useMemo(
    () => miniFormFields && !!miniFormFields.length,
    [miniFormFields]
  );

  const onMiniFormAddClick = useCallback(() => {
    if (!isEmpty(miniFormData)) {
      if (onMiniFormSubmit) {
        const newValue = onMiniFormSubmit(data, miniFormData);
        onChange(newValue);
      } else {
        const newValue = ifElse(
          areAllStrings,
          append(miniFormData[FALLBACK_KEY_FIELD]),
          append(miniFormData)
        )(data);
        onChange(newValue);
      }

      setIsMiniFormVisible(false);
      setMiniFormData({});
    }
  }, [data, miniFormData, onChange, onMiniFormSubmit]);

  const onMiniFormCancelClick = useCallback(() => {
    setIsMiniFormVisible(false);
    setMiniFormData({});
  }, []);

  const formFields = useMemo<FormFields>(
    () => [
      {
        key: 'tableFormRow',
        type: 'row',
        fields: [
          ...miniFormFields,
          {
            key: 'addButton',
            label: miniFormAddLabel,
            type: 'button',
            fieldProps: {
              onClick: onMiniFormAddClick,
              small: true
            }
          },
          {
            key: 'cancelButton',
            label: miniFormCancelLabel,
            type: 'button',
            fieldProps: {
              onClick: onMiniFormCancelClick,
              small: true,
              dark: true
            }
          }
        ],
        style: {
          alignItems: 'center'
        }
      }
    ],
    [
      miniFormAddLabel,
      miniFormCancelLabel,
      miniFormFields,
      onMiniFormAddClick,
      onMiniFormCancelClick
    ]
  );

  const tableNoDataText = useMemo(() => {
    if (noDataText) {
      const emptyState = (
        <Fragment>
          <EmptyStateText>{noDataText}</EmptyStateText>

          <EmptyStateButton
            disabled={isMiniFormVisible}
            onClick={(): void => setIsMiniFormVisible(true)}
          >
            {emptyStateAddNewLabel}
          </EmptyStateButton>
        </Fragment>
      );

      return { noDataText: emptyState };
    }

    return { noDataText };
  }, [emptyStateAddNewLabel, isMiniFormVisible, noDataText]);

  const readOnlyOverrides = useMemo(() => {
    if (isReadOnly) {
      return { allowRowSelect: false };
    }

    return {};
  }, [isReadOnly]);

  return (
    <LabelBox label={label || ''}>
      <Table
        columns={tableColumns}
        data={tableRows}
        keyField={tableKeyField}
        onRowToggle={onRowToggle}
        selectedRows={selectedRows}
        style={{ minHeight: 240 }}
        {...tableNoDataText}
        {...props}
        {...readOnlyOverrides}
      />

      <TableFooter>
        {selectedRows && !!selectedRows.length && (
          <Button background="red" small onClick={onDeleteClick}>
            Delete
          </Button>
        )}

        {enableMiniForm && !isReadOnly && (
          <MiniFormWrap>
            {isMiniFormVisible ? (
              <FormComponent
                fields={formFields}
                gutterSize="small"
                initialData={miniFormData}
                onChange={setMiniFormData}
                showSave={false}
              />
            ) : (
              <Button
                background="greyLight"
                small
                onClick={(e): void => {
                  setIsMiniFormVisible(true);
                  const parent = e.currentTarget.parentNode;

                  if (parent) {
                    setTimeout(() => {
                      const input = parent.querySelector('input');

                      if (input) {
                        input.focus();
                      }
                    }, 0);
                  }
                }}
              >
                {miniFormAddNewLabel}
              </Button>
            )}
          </MiniFormWrap>
        )}
      </TableFooter>
    </LabelBox>
  );
};
