import React from 'react';
import styled from 'styled-components';
import { Button } from '@rollioforce/rollio-ui';

export interface LoadingIndicatorProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
}

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 25;
`;

const IndicatorWrap = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  pointer-events: none;
`;

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  isLoading,
  ...props
}) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Backdrop {...props}>
      <IndicatorWrap>
        <Button loading dark as="div" />
      </IndicatorWrap>
    </Backdrop>
  );
};
