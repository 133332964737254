import React, { useCallback, useMemo, useState } from 'react';
import { useUID } from 'react-uid';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { rgba } from 'polished';
import styled from 'styled-components';

import { colors } from '../../theme';
import { InputBase, InputBaseProps } from '../Input/InputBase';
import { IconProps } from '../Icon';

export type TextareaRefType = HTMLTextAreaElement;

interface NativeTextareaProps
  extends Omit<React.TextareaHTMLAttributes<TextareaRefType>, 'style'> {
  style?: any;
}

type SuggestionData = SuggestionDataItem & {
  render?: (
    suggestion: SuggestionDataItem,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => React.ReactNode;
};

type MentionsData = SuggestionData[];

export interface TextareaProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  autoGrow?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  id?: string;
  input?: NativeTextareaProps;
  label: string;
  mentions?: MentionsData;
  name?: string;
  onChange: (event: React.ChangeEvent<TextareaRefType>) => void;
  value?: string;
}

const TypedInputBase = InputBase as React.FC<
  InputBaseProps<NativeTextareaProps>
>;

const StyledInputBase = styled(TypedInputBase)`
  overflow: initial;
  padding-top: 25px;
`;

const StyledMentionsInput = styled(MentionsInput)`
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  padding: 0px 12px 12px;
  border-radius: 0;
  height: auto;
  min-height: 56px;
`;

const mentionsStyle = {
  input: {
    border: 0,
    padding: '0px 12px 12px',
    outline: 'none',
    color: colors.greyDark,
    lineHeight: '20px'
  },
  highlighter: {
    border: 0
  },
  suggestions: {
    list: {
      position: 'absolute',
      top: 10,
      backgroundColor: 'white',
      borderRadius: 4,
      boxShadow: `0 1px 3px 0 ${colors.offWhite}`
    },
    item: {
      color: colors.greyDark,
      padding: '8px 15px',
      borderBottom: `1px solid ${colors.offWhite}`,
      minWidth: 200,
      '&focused': {
        backgroundColor: colors.offWhite2
      }
    }
  }
};

const mentionItemStyle = {
  color: colors.primary,
  background: rgba(colors.secondary, 0.2),
  position: 'relative',
  zIndex: '1',
  cursor: 'pointer'
};

const textareaStyle = {
  height: 'initial',
  minHeight: '56px',
  lineHeight: '20px',
  display: 'block',
  resize: 'none',
  padding: '0px 12px 12px'
};

const labelProps = {
  style: {
    top: '28px'
  }
};

const iconProps: Omit<IconProps, 'name'> = {
  color: 'greyLight',
  style: {
    boxSizing: 'border-box',
    top: 'inherit',
    bottom: 8,
    right: 8,
    backgroundColor: rgba(colors.greyLight, 0.4),
    borderRadius: 4,
    padding: 4
  }
};

export const Textarea: React.FC<TextareaProps> = ({
  autoGrow,
  disabled,
  id,
  input = {},
  label,
  mentions,
  name,
  onChange,
  value,
  ...props
}) => {
  const [isFocused, setFocus] = useState(false);
  const [rows, setRows] = useState(input.rows || 1);

  const uuid = useUID();
  const inputId = id || `rui_textarea_${uuid}`;

  const onAdjustElementGrow = useCallback((element: TextareaRefType): void => {
    if (element) {
      const style = window.getComputedStyle(element);

      const paddingTop = Number(style.paddingTop.slice(0, -2));
      const paddingBottom = Number(style.paddingBottom.slice(0, -2));
      const lineHeight = Number(style.lineHeight.slice(0, -2));

      const currentRows = Math.round(
        (element.scrollHeight - (paddingTop + paddingBottom)) / lineHeight
      );

      setRows(currentRows);
    }
  }, []);

  const inputProps = useMemo(
    () => ({
      ...input,
      onBlur: (): void => setFocus(false),
      onFocus: (): void => setFocus(true),
      onChange: (event: React.ChangeEvent<TextareaRefType>): void => {
        if (onChange) {
          onChange(event);
        }

        if (autoGrow) {
          onAdjustElementGrow(event.target);
        }
      },
      name,
      rows,
      as: 'textarea',
      style: textareaStyle,
      ...(mentions && {
        as: StyledMentionsInput,
        style: mentionsStyle,
        children: (
          <Mention
            trigger="@"
            data={mentions}
            style={mentionItemStyle}
            renderSuggestion={(
              suggestion: SuggestionData,
              ...params
            ): React.ReactNode => {
              const { render, ...item } = suggestion;
              return render ? render(item, ...params) : item.display;
            }}
          />
        )
      })
    }),
    [autoGrow, input, mentions, name, rows, onChange, onAdjustElementGrow]
  );

  return (
    <StyledInputBase
      {...props}
      id={inputId}
      inputProps={inputProps}
      isDisabled={disabled}
      isFocused={isFocused}
      label={label}
      labelProps={labelProps}
      value={value}
      {...(mentions ? { icon: 'at', iconProps } : {})}
    />
  );
};
