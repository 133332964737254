export type ColorName =
  | 'primary'
  | 'secondary'
  | 'greyLight'
  | 'offWhite'
  | 'offWhite2'
  | 'greyDark'
  | 'white'
  | 'red';

export const colors: { [key in ColorName]: string } = {
  primary: '#1e147d',
  secondary: '#00dcff',
  greyLight: '#898989',
  offWhite: '#f2f2f2',
  offWhite2: '#f9f9f9',
  greyDark: '#515151',
  white: '#ffffff',
  red: '#e73939'
};

type FontName = 'default' | 'mono';

export const fonts: { [key in FontName]: string } = {
  default:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  mono:
    '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace'
};
