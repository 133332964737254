import React, { useCallback, useMemo } from 'react';
import ReactTable, {
  ReactTableDefaults,
  CellInfo as ReactTableCellInfo,
  Column as ReactTableColumn,
  TableProps as ReactTableProps
} from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import styled, { css } from 'styled-components';

import { Checkbox } from '../Checkbox';
import { TableLabel, TableLabelProps, TableLabelVariant } from './Label';
import { colors, fonts } from '../../theme';

export { TableLabel, TableLabelProps };

type RowKeyFieldValue = string | number;

interface Column extends ReactTableColumn {
  cellVariant?: TableLabelVariant;
  isLink?: boolean;
}

export type Columns = Array<Column>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Row = { [key: string]: any };

interface CellInfo extends ReactTableCellInfo {
  column: Column;
}

interface StyledTableProps {
  allowRowSelect?: boolean;
}

export interface TableProps
  extends StyledTableProps,
    Partial<Omit<ReactTableProps, 'column'>> {
  column?: ReactTableColumn;
  data: Array<Row>;
  columns: Columns;
  keyField?: string;
  onRowToggle?: (key: RowKeyFieldValue, isChecked: boolean) => void;
  onToggleAll?: (isChecked: boolean) => void;
  selectedRows?: Array<RowKeyFieldValue>;
}

interface SelectInputComponentProps {
  row: Row;
  checked: boolean;
}

interface SelectAllInputComponentProps {
  checked: boolean;
}

const StyledTable = styled(ReactTable)<StyledTableProps>(
  (props) => css`
    &.ReactTable {
      border: none;

      .rt-thead {
        &.-header {
          box-shadow: none;
        }

        &::after {
          content: '';
          background: ${colors.offWhite};
          height: 3px;
          border-radius: 1.5px;
        }
      }

      .rt-tbody {
        .rt-tr-group {
          border-bottom-color: transparent;

          &:not(:last-child)::after {
            content: '';
            background: ${colors.offWhite};
            height: 1px;
            border-radius: 0.5px;
          }
        }
      }

      .rt-th {
        box-shadow: none;
        font-family: ${fonts.default};
        color: ${colors.greyLight};
        font-size: 14px;
        text-align: left;
        font-weight: 600;
      }

      .rt-td {
        padding-left: 10px;
        padding-right: 10px;
      }

      .rt-th,
      .rt-td {
        border-right: 0 !important;
        border-left: 0 !important;
        font-family: ${fonts.default};
        padding: 16px 5px;

        &:first-child {
          ${!props.allowRowSelect && 'display: none;'}
          ${props.allowRowSelect && 'text-overflow: initial;'}
        }
      }
    }
  `
);

const SelectableTable = selectTableHOC(StyledTable);

const DEFAULT_PROPS = {
  showPagination: false,
  resizable: false,
  minRows: 1
};

export const Table: React.FC<TableProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children,
  column = {},
  data,
  keyField = '',
  columns,
  allowRowSelect = false,
  onRowToggle,
  onToggleAll,
  selectedRows = [],
  ...props
}) => {
  const SelectInputComponent: React.FC<SelectInputComponentProps> = ({
    checked,
    row
  }) => {
    if (allowRowSelect) {
      const onChange = (): void => {
        if (onRowToggle && keyField) {
          onRowToggle(row[keyField], !checked);
        }
      };

      return <Checkbox onChange={onChange} checked={checked} />;
    }

    return null;
  };

  const SelectAllInputComponent: React.FC<SelectAllInputComponentProps> = ({
    checked
  }) => {
    if (onToggleAll) {
      return (
        <Checkbox
          onChange={(): void => onToggleAll(!checked)}
          checked={checked}
        />
      );
    }
    return null;
  };

  const isSelected = useCallback(
    (keyFieldValue) => selectedRows.includes(keyFieldValue),
    [selectedRows]
  );

  const columnOverride = useMemo(
    () => ({
      ...ReactTableDefaults.column,
      Cell: ({ value, column: c }: CellInfo): React.ReactNode => (
        <TableLabel isLink={c.isLink} variant={c.cellVariant}>
          {value}
        </TableLabel>
      ),
      ...column
    }),
    [column]
  );

  const selectAll = useMemo(() => data.length === selectedRows.length, [
    data,
    selectedRows
  ]);

  const tableProps = {
    ...DEFAULT_PROPS,
    ...props,
    allowRowSelect,
    column: columnOverride,
    columns,
    data,
    isSelected,
    keyField,
    selectAll,
    SelectAllInputComponent,
    SelectInputComponent,
    selectedRows
  };

  return <SelectableTable {...tableProps} />;
};
